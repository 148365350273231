
//import Mapbox from 'mapbox-gl-vue';
import mapboxgl, { MapMouseEvent, MapboxEvent } from 'mapbox-gl';
import { Component, Vue } from 'vue-property-decorator';
import { Unit } from '@/interfaces/unit';
import { Farm } from '@/interfaces/farm';
import { Tile } from '@/interfaces/tile';
import { Parcel } from '@/interfaces/parcel';
import Intersect from '@turf/intersect';
import { Feature, Polygon } from 'geojson';
import { LngLat, Marker, LngLatBoundsLike, GeoJSONSource, GeoJSONSourceOptions } from 'mapbox-gl';
import { MapComponent } from '@/interfaces/mapComponent';
import { Survey } from '../interfaces/survey';
import TagMarker from '@/components/TagMarker.vue';
const lineWidthStr = 'line-width';
const lineColorStr = 'line-color';

@Component({
  components: {
    //Mapbox,
    TagMarker
  }
})
export default class Map extends Vue implements MapComponent {
  private readonly segmentColorMap = {
    gap: '#fc0303',
    line: '#03fc7b',
    default: '#000'
  };

  private readonly rasterLayerId = 'rasterLayer';
  private readonly rasterSourceId = 'rasterSource';

  private readonly linesLayerId = 'linesLayer';
  private readonly linesSourceId = 'linesSource';

  private readonly tilesPolygonBorderLayerId = 'tilesPolygonBorderLayer';
  private readonly tilesPolygonLayerId = 'tilesPolygonLayer';
  private readonly tilesPolygonSourceId = 'tilesPolygonSource';

  private readonly selectedTilesPolygonLayerId = 'selectedTilesPolygonLayer';
  private readonly selectedTilesPolygonSourceId = 'selectedTilesPolygonSource';

  private readonly selectedTilesLinesLayerId = 'selectedTilesLinesLayer';
  private readonly selectedTilesLinesSourceId = 'selectedTilesLinesSource';
  unitMarkers = new Array<Marker>();
  farmMarkers = new Array<Marker>();
  private map: mapboxgl.Map;
  tileSelected: Tile;
  private tagsPopup: mapboxgl.Popup;
  selectedFeature: Feature;
  constructor() {
    super();
    this.tileSelected = null;
  }
  mounted() {
    mapboxgl.accessToken =
      'pk.eyJ1Ijoia2lycnVraXJydSIsImEiOiJjazJhMmJ6anMxMGh5M21tczJ6NTEwaW4yIn0.irfuud6XtRKV6K7hSv-bkQ';
    this.map = new mapboxgl.Map({
      container: 'map',
      zoom: 1,
      minZoom: 1,
      maxZoom: 24,
      preserveDrawingBuffer: true,
      style: 'mapbox://styles/mapbox/satellite-v9'
    });
    this.map.on('load', () => {
      this.onMapLoaded();
    });
    this.$store.dispatch('showGlobalLoader', true);
  }

  onMapLoaded() {
    //this.map = map;
  }
  addMarker(loc: LngLat, title: string, clr: string): Marker {
    /*const r = Math.round(Math.random() * 255);
    const g = Math.round(Math.random() * 255);
    const b = Math.round(Math.random() * 255);*/
    const marker = new Marker({ color: clr /*`rgb(${r}, ${g}, ${b})`*/ }).setLngLat(loc).addTo(this.map);
    marker.getElement().setAttribute('title', title);
    return marker;
  }
  addUnit(unit: Unit, tileCount: number) {
    if (unit.URLong == null) {
      return;
    }
    const loc = new LngLat((unit.URLong + unit.LLLong) / 2, (unit.URLat + unit.LLLat) / 2);
    const marker = this.addMarker(loc, unit.Name, tileCount > 0 ? 'orange' : 'red');
    marker.getElement().addEventListener('click', () => {
      this.selectUnit(unit);
    });
    this.unitMarkers.push(marker);
  }
  addFarm(farm: Farm, tileCount: number) {
    const loc = new LngLat((farm.URLong + farm.LLLong) / 2, (farm.URLat + farm.LLLat) / 2);
    const marker = this.addMarker(loc, farm.Name, tileCount > 0 ? 'orange' : 'red');
    marker.getElement().addEventListener('click', () => {
      this.selectFarm(farm);
    });
    this.farmMarkers.push(marker);
  }
  showParcel(parcel: Parcel) {
    this.map.addLayer({
      id: 'parcel-' + parcel.id,
      type: 'line',
      source: {
        type: 'geojson',
        data: parcel.Shape
      },
      paint: {
        [lineColorStr]: '#ffff00',
        [lineWidthStr]: 2
      }
    });
  }
  showSurvey(survey: Survey, parcel: Parcel) {
    const ll = this.getXY(parcel.LLLat, parcel.LLLong, 20);
    const ur = this.getXY(parcel.URLat, parcel.URLong, 20);
    const parcelBounds = [parcel.LLLong, parcel.LLLat, parcel.URLong, parcel.URLat];
    this.map.addLayer(
      {
        id: 'survey-' + survey.id,
        type: 'raster',
        source: {
          type: 'raster',
          tiles: [
            `https://storage.googleapis.com/drone-tiles-${process.env.VUE_APP_ENV}/${
              survey.id
            }/{z}/{x}/{y}.png?v=${new Date().getTime()}`
          ],
          bounds: parcelBounds
        }
      },
      'parcel-' + parcel.id
    );
    const features = new Array<Feature>();
    const boxes = new Array<Feature>();

    for (let x = ll.x; x <= ur.x; x++) {
      for (let y = ur.y; y <= ll.y; y++) {
        const tl = this.getLatLngFromTile(x, y, 20);
        const br = this.getLatLngFromTile(x + 1, y + 1, 20);
        const coords = [
          [
            [tl.lng, tl.lat],
            [tl.lng, br.lat],
            [br.lng, br.lat],
            [br.lng, tl.lat],
            [tl.lng, tl.lat]
          ]
        ];
        const featPoly: Polygon = { type: 'Polygon', coordinates: coords };
        const intersection = Intersect(featPoly, parcel.Shape.features[0]);
        if (intersection != null) {
          const id = survey.id + '_' + x + '_' + y;
          const clr = this.getTileColor(id);
          features.push({
            type: 'Feature',
            properties: {
              id: id,
              color: clr,
              tags: this.$store.state.tileTags != null ? JSON.stringify(this.$store.state.tileTags[id]) : null
            },
            geometry: featPoly
          });
          //if (clr != 'white') {
          boxes.push({
            type: 'Feature',
            properties: {
              id: id,
              color: clr != 'white' ? clr : 'transparent',
              tags: this.$store.state.tileTags != null ? JSON.stringify(this.$store.state.tileTags[id]) : null
            },
            geometry: featPoly
          });
          //}
        }
      }
    }
    const layerId = 'survey-tiles-' + survey.id;
    this.map.addLayer({
      id: layerId,
      type: 'fill',
      minzoom: 16,
      source: {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: features }
      },
      paint: {
        'fill-color': 'rgba(200, 200, 200, 0.1)',
        'fill-outline-color': 'white' //['get', 'color']
      }
    });
    this.map.addLayer({
      id: 'boxes-' + survey.id,
      type: 'line',
      minzoom: 16,
      source: {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: boxes }
      },
      paint: {
        'line-color': ['get', 'color'],
        'line-width': 3
      }
    });
    /*features.map(f => {
      const el = document.createElement('div');
      el.id = 'tags-' + f.properties.id;
      el.className = 'tags';
      el.style.top = '30px';
      el.style.left = '30px';
      el.style.color = 'white';
      el.style.display = 'none';
      const coord = (f.geometry as Polygon).coordinates;
      el.innerHTML =
        '<input type="checkbox"> Tag1<br/>' +
        '<input type="checkbox"> Tag2<br/>' +
        '<input type="button" value="Label" onclick=';
      new Marker(el).setLngLat([coord[0][0][0], coord[0][0][1]]).addTo(this.map);
    });*/
    this.tagsPopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false
    });
    this.map.on('mousemove', layerId, (ev) => {
      const tileId = ev.features[0].properties.id;
      const list = this.$store.state.tileTags[tileId];
      if (list == null) {
        this.tagsPopup.remove();
        return;
      }
      let tags = list.join('<br/>');
      if (tags.length == 0) {
        tags = '<i>No Tags</i>';
      }
      const pts = tileId.split('_');
      const x = parseInt(pts[1]);
      const y = parseInt(pts[2]);
      const coords = this.getLatLngFromTile(x, y, 20);
      const coords2 = this.getLatLngFromTile(x + 1, y, 20);
      this.tagsPopup
        .setLngLat([(coords.lng + coords2.lng) / 2, coords.lat])
        .setHTML(tags)
        .addTo(this.map);
    });
    this.map.on('mouseleave', layerId, (ev) => {
      this.tagsPopup.remove();
    });
    this.map.on('click', layerId, (ev) => {
      this.selectedFeature = ev.features[0];
      this.selectTags(ev.features[0].properties.id);
    });
  }
  getTileColor(tileId: string) {
    let clr = 'white';
    if (this.$store.state.tileTags != null && this.$store.state.tileTags[tileId] != null) clr = 'yellow';
    if (this.$store.state.lockedTiles != null && this.$store.state.lockedTiles[tileId] != null) clr = 'orange';
    if (this.$store.state.approvedTiles != null && this.$store.state.approvedTiles[tileId] != null) clr = 'green';
    return clr;
  }
  onTagsSet(tileId: string, hasTags: boolean) {
    const src = this.map.getSource('boxes-' + tileId.split('_')[0]);
    const fc = (src as any)._data as any;
    fc.features.map((f) => {
      if (f.properties.id == tileId) {
        f.properties.color = hasTags ? 'orange' : 'rgba(200, 100, 240, 1)';
      }
    });
    (src as GeoJSONSource).setData(fc);
  }

  selectTags(tileId: string) {
    const pts = tileId.split('_');
    this.tileSelected = { id: tileId, survey: pts[0], x: parseInt(pts[1]), y: parseInt(pts[2]), tags: [] };
    this.onTagsSet(tileId, true);
    //this.$emit('onTileSelected', );
    //(document.querySelector('#tags-' + tileId) as HTMLDivElement).style.display = '';
  }
  get markerTop() {
    const loc = this.getLatLngFromTile(this.tileSelected.x, this.tileSelected.y, 20);
    const pt = this.map.project({ lng: loc.lng, lat: loc.lat });
    const rect = this.map.getContainer().getBoundingClientRect();
    return rect.y + Math.round(pt.y);
  }

  get markerLeft() {
    const loc = this.getLatLngFromTile(this.tileSelected.x, this.tileSelected.y, 20);
    const pt = this.map.project({ lng: loc.lng, lat: loc.lat });
    const rect = this.map.getContainer().getBoundingClientRect();
    return rect.x + Math.round(pt.x);
  }

  getLatLngFromTile(x: number, y: number, z: number): { lat: number; lng: number; zoom: number } {
    const lng = (x / Math.pow(2, z)) * 360 - 180;

    const n = Math.PI - (2 * Math.PI * y) / Math.pow(2, z);
    const lat = (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
    return { lat: lat, lng: lng, zoom: z };
  }
  getXY(lat: number, lon: number, zoom: number): { x: number; y: number; z: number } {
    const latRad = (lat * Math.PI) / 180;
    const xtile = Math.floor(((lon + 180) / 360) * (1 << zoom));
    const ytile = Math.floor(((1 - Math.log(Math.tan(latRad) + 1 / Math.cos(latRad)) / Math.PI) / 2) * (1 << zoom));
    return { x: xtile, y: ytile, z: zoom };
  }
  removeUnits() {
    this.removeMarkers(this.unitMarkers);
    this.unitMarkers = new Array<Marker>();
  }
  removeMarkers(arr: Array<Marker>) {
    if (arr !== null) {
      for (let i = arr.length - 1; i >= 0; i--) {
        arr[i].remove();
      }
    }
  }
  removeFarms() {
    this.removeMarkers(this.farmMarkers);
    this.farmMarkers = new Array<Marker>();
  }
  selectUnit(unit: Unit) {
    this.$emit('onUnitSelected', unit);
  }
  selectFarm(farm: Farm) {
    this.$emit('onFarmSelected', farm);
  }
  setBounds(bounds: LngLatBoundsLike) {
    this.map.fitBounds(bounds, { duration: 0 });
  }
}
