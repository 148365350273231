
import { Component, Vue, Prop } from 'vue-property-decorator';
import API from '@/services/api';
interface ColorType {
  red: number;
  green: number;
  blue: number;
  opacity: number;
}
interface LableType {
  name: string;
  color: ColorType;
  index: number;
}
interface Brush {
  text: string;
}

@Component({
  components: {}
})
export default class Editor extends Vue {
  @Prop()
  X: number;
  @Prop()
  Y: number;
  @Prop()
  survey: string;
  CANVAS_SIZE = 512;
  TILE_SIZE = 256;
  img: any;
  mode: string;
  dragStart: DOMPoint;
  dragging = false;
  itemIdx = 0;
  cursorSize: number;
  items: any[];
  imgs: Map<string, any>;
  lastX: number;
  lastY: number;
  scaleFactor: number;
  transparency: number;
  undoItems: any[];
  labels: LableType[] = [];
  activeLabel: LableType;
  brushes: Brush[] = [
    { text: 'Classic' },
    { text: 'On Void' },
    { text: 'Flood' },
    { text: 'Flood By Color' },
    { text: 'Erase' },
    { text: 'Erase active' }
  ];
  activeBrush: Brush;

  $refs: {
    bgCanvas: HTMLCanvasElement;
    drawingCanvas: HTMLCanvasElement;
  };
  bgCtx: CanvasRenderingContext2D;
  ctx: CanvasRenderingContext2D;
  constructor() {
    super();
    this.lastX = this.CANVAS_SIZE / 2;
    this.lastY = this.CANVAS_SIZE / 2;
    this.imgs = new Map<string, any>();
    this.transparency = 0;
    this.labels = [
      { name: 'Crop area without plants', color: { red: 98, green: 135, blue: 236, opacity: 1.0 }, index: 1 },
      { name: 'Crop < 1 month', color: { red: 255, green: 153, blue: 51, opacity: 1.0 }, index: 2 },
      { name: 'Crop 2-3 months', color: { red: 255, green: 255, blue: 0, opacity: 1.0 }, index: 8 },
      { name: 'Crop > 4 months', color: { red: 153, green: 255, blue: 204, opacity: 1.0 }, index: 9 },
      { name: 'Weeds grass-like', color: { red: 255, green: 0, blue: 102, opacity: 1.0 }, index: 3 },
      { name: 'Weeds bush-like', color: { red: 204, green: 51, blue: 255, opacity: 1.0 }, index: 4 },
      { name: 'Weeds climbing', color: { red: 71, green: 107, blue: 107, opacity: 1.0 }, index: 10 },
      { name: 'Weeds broadleaves', color: { red: 204, green: 0, blue: 204, opacity: 1.0 }, index: 11 },
      { name: 'Wet vinassa channels', color: { red: 255, green: 255, blue: 255, opacity: 1.0 }, index: 12 },
      { name: 'Water', color: { red: 179, green: 0, blue: 0, opacity: 1.0 }, index: 7 },
      { name: 'Other areas', color: { red: 102, green: 255, blue: 51, opacity: 1.0 }, index: 5 },
      { name: 'Other vegetation', color: { red: 102, green: 204, blue: 255, opacity: 1.0 }, index: 6 },
      { name: 'Unclassified', color: { red: 0, green: 0, blue: 0, opacity: 0.0 }, index: 0 }
    ];
    this.activeBrush = this.brushes[0];
    this.activeLabel = this.labels[0];
    this.items = [];
    this.scaleFactor = 1.1;
    this.mode = 'drawing';
    this.undoItems = [];
    this.cursorSize = 6;
  }
  mounted() {
    this.bgCtx = this.$refs.bgCanvas.getContext('2d');
    this.ctx = this.$refs.drawingCanvas.getContext('2d');
    this.drawBackground();
  }
  drawBackground() {
    for (let x = 0; x < this.CANVAS_SIZE / this.TILE_SIZE; x++) {
      for (let y = 0; y < this.CANVAS_SIZE / this.TILE_SIZE; y++) {
        const img = this.imgs.get('' + x + '_' + y);
        if (img != null) {
          this.bgCtx.drawImage(img, x * this.TILE_SIZE, y * this.TILE_SIZE, this.TILE_SIZE, this.TILE_SIZE);
        } else {
          const image = new Image();
          image.src =
            process.env.VUE_APP_LABELLING_SERVER +
            '/get?url=' +
            encodeURIComponent(
              'https://storage.googleapis.com/drone-tiles-' +
                process.env.VUE_APP_ENV +
                '/' +
                this.survey +
                '/21/' +
                (this.X * 4 + x) +
                '/' +
                (this.Y * 4 + y) +
                '.png'
            );
          image.crossOrigin = 'Anonymous';
          image.onload = () => {
            this.imgs.set('' + x + '_' + y, image);
            this.bgCtx.drawImage(image, x * this.TILE_SIZE, y * this.TILE_SIZE, this.TILE_SIZE, this.TILE_SIZE);
          };
        }
      }
    }
  }
  // Event handlers
  mouseMove(ev: MouseEvent): void {
    const pt = this.getPointOnElement(ev);
    this.lastX = pt.x;
    this.lastY = pt.y;
    if (this.dragging) {
      const pt2 = this.transformedPointInv(pt.x, pt.y);
      if (this.mode === 'drawing') {
        this.drawOnCanvas({ x: pt2.x, y: pt2.y });
      } else {
        this.bgCtx.translate(pt2.x - this.dragStart.x, pt2.y - this.dragStart.y);
        this.ctx.translate(pt2.x - this.dragStart.x, pt2.y - this.dragStart.y);
        this.redraw();
      }
    }
  }

  mouseDown(ev: MouseEvent): void {
    const pt = this.getPointOnElement(ev);
    this.lastX = pt.x;
    this.lastY = pt.y;
    this.dragStart = this.transformedPointInv(pt.x, pt.y);
    if (this.mode == 'drawing' && (this.activeBrush.text == 'Flood' || this.activeBrush.text == 'Flood By Color')) {
      this.drawOnCanvas({ x: pt.x, y: pt.y });
    } else {
      this.dragging = true;
    }
  }
  mouseUp(): void {
    this.dragging = false;
  }

  mouseWheel(evt: WheelEvent): void {
    const delta = evt.deltaY ? evt.deltaY / 40 : evt.detail ? -evt.detail : 0;
    if (delta === 0) {
      return;
    }
    if (this.mode === 'drawing') {
      this.cursorSize += delta > 0 ? 1 : -1;
      if (this.cursorSize < 1) {
        this.cursorSize = 1;
      }
      if (this.cursorSize > 10) {
        this.cursorSize = 10;
      }
    } else {
      this.zoom(delta);
    }
  }
  keyPress(evt: KeyboardEvent): void {
    if (evt.code === 'Space') {
      this.mode = this.mode === 'drawing' ? 'zooming' : 'drawing';
    }
  }
  onTransparencyChange(): void {
    this.redraw();
  }
  save(): void {
    this.ctx.save();
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.redraw(false);
    this.$refs.drawingCanvas.toBlob((blob) => {
      new Response(blob).arrayBuffer().then((buffer) => {
        API.saveLabels(this.survey + '_' + this.X + '_' + this.Y, false, this.$store.state.user.UserInfo.id, buffer); //.then(success => {});
      });
      this.ctx.restore();
      this.redraw();
    });
  }
  restore(): void {
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    this.bgCtx.setTransform(1, 0, 0, 1, 0, 0);
    this.redraw();
  }
  undo(): void {
    if (this.items.length > 0) {
      this.undoItems.push(this.items.pop());
      this.redraw();
    }
  }

  redo(): void {
    if (this.undoItems.length > 0) {
      this.items.push(this.undoItems.pop());
      this.redraw();
    }
  }
  zoom(clicks: number): void {
    const pt = this.transformedPointInv(this.lastX, this.lastY);
    this.applyTransform(this.bgCtx, pt, clicks);
    this.applyTransform(this.ctx, pt, clicks);
    this.redraw();
  }

  applyTransform(ctx: CanvasRenderingContext2D, pt: DOMPoint, clicks: number): void {
    ctx.translate(pt.x, pt.y);
    const factor = Math.pow(this.scaleFactor, clicks);
    ctx.scale(factor, factor);
    ctx.translate(-pt.x, -pt.y);
  }
  redraw(withBg = true): void {
    // Clear the entire canvas
    this.clearCanvas(this.bgCtx);
    this.clearCanvas(this.ctx);
    if (withBg) {
      this.bgCtx.globalAlpha = (100 - this.transparency) / 100;
      //this.bgCtx.drawImage(this.img, 0, 0);
      this.drawBackground();
    }
    this.ctx.globalAlpha = 1;
    this.itemIdx = 0;
    this.drawItems();
  }
  getPointOnElement(ev: MouseEvent): { x: number; y: number } {
    const rect = (ev.target as HTMLElement).getBoundingClientRect();
    return {
      x: ev.clientX - rect.left,
      y: ev.clientY - rect.top
    };
  }
  clearCanvas(ctx: CanvasRenderingContext2D): void {
    const p1 = this.transformedPoint(0, 0);
    const p2 = this.transformedPoint(this.CANVAS_SIZE, this.CANVAS_SIZE);
    ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, this.CANVAS_SIZE, this.CANVAS_SIZE);
    ctx.restore();
  }
  rgbArray(rgb: string): Uint8ClampedArray {
    const clr = new Uint8ClampedArray(4);
    clr[0] = parseInt(rgb.substr(1, 2), 16);
    clr[1] = parseInt(rgb.substr(3, 2), 16);
    clr[2] = parseInt(rgb.substr(5, 2), 16);
    clr[3] = 255;
    return clr;
  }
  drawItems(): void {
    if (this.itemIdx >= this.items.length) {
      return;
    }
    for (let i = this.itemIdx; i < Math.min(this.itemIdx + 10, this.items.length); i++, this.itemIdx++) {
      const item = this.items[i];
      this.drawItem(item, true);
    }
    requestAnimationFrame(this.drawItems.bind(this));
  }

  drawOnCanvas(currentPos: { x: number; y: number }): void {
    if (!this.ctx) {
      return;
    }

    if (currentPos) {
      const item = {
        action: this.activeBrush.text,
        point: currentPos,
        width: this.cursorSize,
        color: this.getAsRGB(this.activeLabel.color)
      };
      this.items.push(item);
      this.drawItem(item);
    }
  }
  getAsRGB(clr: ColorType): string {
    return `#${clr.red.toString(16).padStart(2, '0')}${clr.green.toString(16).padStart(2, '0')}${clr.blue
      .toString(16)
      .padStart(2, '0')}`;
  }
  drawItem(item: any, isRedraw = false): void {
    this.ctx.beginPath();

    let pos = item.point;
    let scaledWidth = item.width;
    switch (item.action) {
      case 'Flood':
        this.floodFill(this.ctx, pos.x, pos.y, this.rgbArray(item.color));
        break;
      case 'Flood By Color':
        this.floodFillByColor(this.ctx, pos.x, pos.y, this.rgbArray(item.color));
        break;
      case 'On Void':
        {
          if (isRedraw) {
            scaledWidth *= this.ctx.getTransform().a;
          }
          pos = this.transformedPoint(item.point.x, item.point.y);
          const clr = this.rgbArray(item.color);
          this.setCanvasColor(pos, Math.round(scaledWidth), 0, clr);
        }
        break;
      case 'Erase':
        this.ctx.globalCompositeOperation = 'destination-out';
        this.ctx.arc(pos.x + scaledWidth, pos.y + scaledWidth, scaledWidth, 0, Math.PI * 2, false);
        this.ctx.fill();
        this.ctx.globalCompositeOperation = 'source-over';
        break;
      case 'Erase active':
        {
          if (isRedraw) {
            scaledWidth *= this.ctx.getTransform().a;
          }
          const clr = parseInt(item.color.substr(1), 16);
          const target = new Uint8ClampedArray(4);
          this.setCanvasColor(pos, Math.round(scaledWidth), clr, target);
        }
        break;
      default:
        this.ctx.fillStyle = item.color;
        this.ctx.arc(pos.x + scaledWidth, pos.y + scaledWidth, scaledWidth, 0, Math.PI * 2, false);
        this.ctx.fill();
        break;
    }
    this.ctx.closePath();
  }
  setCanvasColor(pos: DOMPoint, width: number, clr: number, target: Uint8ClampedArray): void {
    const rad = width;
    const imgData = this.ctx.getImageData(pos.x, pos.y, rad * 2, rad * 2);

    const radSquare = rad * rad;
    let pix = 0;
    for (let j = -rad; j < rad; j += 1) {
      for (let k = -rad; k < rad; k += 1, pix += 4) {
        if (j * j + k * k < radSquare) {
          const rgb = (imgData.data[pix] << 16) | (imgData.data[pix + 1] << 8) | imgData.data[pix + 2];
          if (rgb === clr) {
            imgData.data[pix] = target[0];
            imgData.data[pix + 1] = target[1];
            imgData.data[pix + 2] = target[2];
            imgData.data[pix + 3] = target[3];
          }
        }
      }
    }
    this.ctx.putImageData(imgData, pos.x, pos.y);
  }
  getPixel(imageData: ImageData, x: number, y: number): Uint8ClampedArray {
    const offset = (y * imageData.width + x) * 4;
    return imageData.data.slice(offset, offset + 4);
  }

  setPixel(imageData: ImageData, x: number, y: number, color: Uint8ClampedArray): void {
    const offset = (y * imageData.width + x) * 4;
    imageData.data[offset + 0] = color[0];
    imageData.data[offset + 1] = color[1];
    imageData.data[offset + 2] = color[2];
    imageData.data[offset + 3] = color[3];
  }

  colorsMatch(a: Uint8ClampedArray, b: Uint8ClampedArray, rangeSq: number): boolean {
    const dr = a[0] - b[0];
    const dg = a[1] - b[1];
    const db = a[2] - b[2];
    const da = a[3] - b[3];
    return dr * dr + dg * dg + db * db + da * da < rangeSq;
  }
  floodFillByColor(ctx: CanvasRenderingContext2D, xt: number, yt: number, fillColor: Uint8ClampedArray): void {
    const start = new Date().getTime();
    const target = this.bgCtx.getImageData(xt, yt, 1, 1);
    const bgImageData = this.bgCtx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height).data;
    const imageData = this.bgCtx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
    for (let x = 0; x < ctx.canvas.width; x++) {
      for (let y = 0; y < ctx.canvas.height; y++) {
        const pos = (y * imageData.width + x) * 4;
        const clr = new Uint8ClampedArray([
          bgImageData[pos],
          bgImageData[pos + 1],
          bgImageData[pos + 2],
          bgImageData[pos + 3]
        ]);
        if (this.colorsMatch(clr, target.data, 1000)) {
          this.setPixel(imageData, x, y, fillColor);
        }
      }
    }
    ctx.putImageData(imageData, 0, 0);
  }
  floodFill(ctx: CanvasRenderingContext2D, x: number, y: number, fillColor: Uint8ClampedArray, range = 1): void {
    // read the pixels in the canvas
    const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);

    // flags for if we visited a pixel already
    const visited = new Uint8Array(imageData.width * imageData.height);

    // get the color we're filling
    const targetColor = this.getPixel(imageData, x, y);

    // check we are actually filling a different color
    if (!this.colorsMatch(targetColor, fillColor, 1)) {
      const rangeSq = range * range;
      const pixelsToCheck = [x, y];
      while (pixelsToCheck.length > 0) {
        const y = pixelsToCheck.pop();
        const x = pixelsToCheck.pop();

        const currentColor = this.getPixel(imageData, x, y);
        if (!visited[y * imageData.width + x] && this.colorsMatch(currentColor, targetColor, rangeSq)) {
          this.setPixel(imageData, x, y, fillColor);
          visited[y * imageData.width + x] = 1; // mark we were here already
          pixelsToCheck.push(x + 1, y);
          pixelsToCheck.push(x - 1, y);
          pixelsToCheck.push(x, y + 1);
          pixelsToCheck.push(x, y - 1);
        }
      }
      // put the data back
      ctx.putImageData(imageData, 0, 0);
    }
  }

  transformedPointInv(x: number, y: number): DOMPoint {
    const pt = { x, y } as DOMPoint;
    return this.ctx.getTransform().inverse().transformPoint(pt);
  }

  transformedPoint(x: number, y: number): DOMPoint {
    const pt = { x, y } as DOMPoint;
    return this.ctx.getTransform().transformPoint(pt);
  }
}
