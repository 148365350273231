import { render, staticRenderFns } from "./Editor.vue?vue&type=template&id=97b08c40&scoped=true&"
import script from "./Editor.vue?vue&type=script&lang=ts&"
export * from "./Editor.vue?vue&type=script&lang=ts&"
import style0 from "./Editor.vue?vue&type=style&index=0&id=97b08c40&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97b08c40",
  null
  
)

export default component.exports