
import { Prop, Component, Vue } from 'vue-property-decorator';
import API from '../services/api';
import { Tag } from '../interfaces/tag';
@Component({})
export default class LabelEditor extends Vue {
  tags = [
    { name: 'Weeds', exists: false, notExists: false },
    { name: 'Gaps', exists: false, notExists: false },
    { name: 'Roads', exists: false, notExists: false }
  ];
  @Prop()
  left: number;
  @Prop()
  top: number;
  @Prop()
  tile: string;

  constructor() {
    super();
  }
  updateTag(tag: string, ev: any) {
    const tags = [];
    this.tags.forEach(t => {
      if (t.exists) {
        tags.push(t.name + '_yes');
      }
      if (t.notExists) {
        tags.push(t.name + '_no');
      }
    });
    API.setTileTags(this.tile, tags);
  }
}
