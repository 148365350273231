import Axios from 'axios';

import { RefreshSessionResponse } from '@/interfaces/auth';
import { Labeller } from '@/interfaces/labeller';
import { Tile } from '@/interfaces/tile';
import { User } from '@/interfaces/user';

import { Country } from '../interfaces/country';
import { Farm } from '../interfaces/farm';
import { Parcel } from '../interfaces/parcel';
import { Survey, SurveyAnalytic } from '../interfaces/survey';
import { Tag } from '../interfaces/tag';
import { Unit } from '../interfaces/unit';

class API {
  static _URL = `${process.env.VUE_APP_LABELING_SERVICE}/api`;
  static _PRODUCT_MANAGER_URL = `${process.env.VUE_APP_PRODUCT_MANAGER}/api`;
  static _LABELLING_SERVER_URL = `${process.env.VUE_APP_LABELLING_SERVER}`;

  static getCountries(): Promise<Country[]> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/countries?valid=true`, { withCredentials: true }).then(
      (result) => result.data
    );
  }
  static getUnits(country: string): Promise<Unit[]> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/units?country=` + country, { withCredentials: true }).then(
      (result) => result.data
    );
  }
  static async getSurveyUnits(): Promise<Unit[]> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/unit-surveys`, { withCredentials: true }).then((result) => {
      const units = result.data;
      units.map((u: Unit) => {
        u.SurveyCount = 0;
        u.Farms.map((f: Farm) => {
          u.SurveyCount += f.SurveyCount;
        });
      });
      return units;
    });
  }
  static async getAllUnits(): Promise<Unit[]> {
    /*const countires = await this.getCountries();
    const getUnitsPromises = countires.map(c => {
      return this.getUnits(c.id);
    });
    let units = new Array<Unit>();
    await Promise.all(getUnitsPromises).then(res => {
      res.map(x => {
        units = units.concat(x);
      });
    });
    return units;
    */
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/units`, { withCredentials: true }).then((result) => result.data);
  }
  static getFarms(unit: string): Promise<Farm[]> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/farms?unit=` + unit, { withCredentials: true }).then(
      (result) => result.data
    );
  }
  static getParcels(farm: string): Promise<Parcel[]> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/parcels?farm=` + farm, { withCredentials: true }).then(
      (result) => result.data
    );
  }
  static getSurveys(parcel: string): Promise<Survey[]> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/parcel-analytics?parcel=${parcel}&from=2010-01-01&to=2024-12-31`, {
      withCredentials: true
    }).then((result) => {
      if (result.data.Surveys)
        return result.data.Surveys.filter((x: Survey) =>
          x.SurveyAnalytic.find((sa: SurveyAnalytic) => sa.Type == 'sowing' || sa.Type == 'weeds')
        );
      return null;
    });
  }
  static getSurvey(survey: string): Promise<Survey> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/survey?surveyId=${survey}&source=drone`, {
      withCredentials: true
    }).then((result) => result.data);
  }
  static getSurveyHierarchy(survey: string): Promise<Survey> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/survey-hierarchy?id=` + survey, { withCredentials: true }).then(
      (result) => result.data
    );
  }
  static getParcel(parcel: string): Promise<Parcel> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/parcel?id=` + parcel, { withCredentials: true }).then(
      (result) => result.data
    );
  }
  static getFarm(farm: string): Promise<Farm> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/farm?id=` + farm, { withCredentials: true }).then(
      (result) => result.data
    );
  }
  static getUnit(unit: string): Promise<Unit> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/unit?id=` + unit, { withCredentials: true }).then(
      (result) => result.data
    );
  }

  static authorize(): Promise<void> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/authorize`, { withCredentials: true });
  }

  static getUser(): Promise<User> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/get-user`, { withCredentials: true }).then((result) => result.data);
  }

  static getLabeller(id: string): Promise<Labeller> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/user?id=` + id, { withCredentials: true })
      .then((result) => result.data)
      .catch(() => null);
  }

  static getTags(): Promise<string[]> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/tags`, { withCredentials: true }).then((result) => result.data);
  }
  static saveTags(tags: string[]) {
    return Axios.post(`${API._LABELLING_SERVER_URL}/tags`, tags, { withCredentials: true }).then(
      (result) => result.data
    );
  }
  static setTileTags(tileId: string, tags: string[]) {
    return Axios.post(`${API._LABELLING_SERVER_URL}/settiletags?id=` + tileId, tags, { withCredentials: true }).then(
      (result) => result.data
    );
  }
  static getTaggedTiles(surveyId: string): Promise<Map<string, any>> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/taggedtiles?survey=` + surveyId, { withCredentials: true }).then(
      (result) => result.data
    );
  }
  static saveLabels(id: string, finished: boolean, user: string, body: ArrayBuffer): Promise<boolean> {
    return Axios.post(
      `${API._LABELLING_SERVER_URL}/savelabels?id=` + id + '&user=' + user + '&finished=' + finished,
      body,
      {
        withCredentials: true
      }
    ).then((result) => result.data.success);
  }

  static lockTile(id: string, user: string): Promise<boolean> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/locktile?id=` + id + '&user=' + user, {
      withCredentials: true
    }).then((result) => result.data.success);
  }

  static lockedTiles(): Promise<any> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/lockedtiles`, {
      withCredentials: true
    }).then((result) => result.data);
  }
  static finishedTiles(): Promise<any> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/finishedtiles?include_processed=false`, {
      withCredentials: true
    }).then((result) => result.data);
  }
  static myFinishedTiles(userId: string): Promise<any> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/finishedtiles?user=${userId}&include_processed=true`, {
      withCredentials: true
    }).then((result) => result.data);
  }
  static processedTiles(): Promise<any> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/processedtiles`, {
      withCredentials: true
    }).then((result) => result.data);
  }
  static approvedTiles(): Promise<any> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/approvedtiles?exclude_processed=true`, {
      withCredentials: true
    }).then((result) => result.data);
  }
  static getAllTiles(): Promise<Tile[]> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/get_tiles`, {
      withCredentials: true
    }).then((result) => {
      const data = result.data;
      const tiles = [];
      Object.keys(data).forEach((id: string) => {
        const tile = data[id] as Tile;
        tile.id = id;
        tiles.push(data[id]);
      });
      return tiles;
    });
  }

  static approveLabelling(tileId: string, user: string, approver: string): Promise<any> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/approve?id=` + tileId + '&user=' + user + '&approver=' + approver, {
      withCredentials: true
    }).then((result) => result.data);
  }
  static rejectLabelling(tileId: string, user: string): Promise<any> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/reject?id=` + tileId + '&user=' + user, {
      withCredentials: true
    }).then((result) => result.data);
  }

  static unlockTile(id: string, user: string): Promise<boolean> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/releasetile?id=` + id + '&user=' + user, {
      withCredentials: true
    }).then((result) => result.data.success);
  }

  static tilestatus(id: string): Promise<string> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/tilestatus?id=${id}`, {
      withCredentials: true
    }).then((result) => result.data.status);
  }

  static setTilestatus(id: string, status: string): Promise<boolean> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/settilestatus?id=${id}&status=${status}`, {
      withCredentials: true
    }).then((result) => result.data.success);
  }
  static finishLabelling(id: string, user: string): Promise<boolean> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/finishlabelling?id=` + id + '&user=' + user, {
      withCredentials: true
    }).then((result) => result.data.success);
  }
  static addTag(name: string, data: any): Promise<boolean> {
    return Axios.post(`${API._LABELLING_SERVER_URL}/addtag?tag=` + name, data, {
      withCredentials: true
    }).then((result) => result.data.success);
  }

  static getTile(id: string): Promise<Tile> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/get_tile?id=` + id, {
      withCredentials: true
    }).then((result) => result.data);
  }

  static getTaggedImage(id: string, user: string): Promise<any> {
    return Axios.get(`${API._LABELLING_SERVER_URL}/taggedimage?id=` + id + '&user=' + user, {
      responseType: 'blob',
      withCredentials: true
    }).then((result) => {
      return URL.createObjectURL(result.data);
    });
  }

  static saveNotes(id: string, notes: string): Promise<boolean> {
    return Axios.post(`${API._LABELLING_SERVER_URL}/savenotes?id=` + id, notes, {
      withCredentials: true
    }).then((result) => result.data.success);
  }
  static parseTag(tag: string): Tag {
    const pts = tag.split('_');
    return {
      name: pts[0],
      exists: pts[1] == 'yes',
      notExists: pts[2] == 'yes'
    };
  }

  static refreshSession(): Promise<RefreshSessionResponse> {
    return Axios.get(`${API._PRODUCT_MANAGER_URL}/refresh-session`, { withCredentials: true }).then(
      (result) => result.data
    );
  }
}

export default API;
