
import { Prop, Component, Vue } from 'vue-property-decorator';
import API from '../services/api';
import { Tag } from '../interfaces/tag';
@Component({ components: { VNodes: { functional: true, render: (h, ctx) => ctx.props.vnodes } } })
export default class TagMarker extends Vue {
  tags: any;
  tagKeys: string[];
  selectedTags: any;
  @Prop()
  left: number;
  @Prop()
  top: number;
  @Prop()
  tile: string;

  constructor() {
    super();
    this.tags = this.$store.state.tags;
    this.tagKeys = Object.keys(this.$store.state.tags);

    if (this.$store.state.tileTags != null) this.selectedTags = this.$store.state.tileTags[this.tile];
    if (this.selectedTags == null) this.selectedTags = this.$store.state.selectedTag;
  }
  mounted() {
    if (this.selectedTags.some(t => this.$store.state.selectedTag.some(tt => tt == t))) {
      this.selectedTags = this.$store.state.selectedTag;
      setTimeout(() => {
        this.updateTag(this.$store.state.selectedTag, null);
      }, 100);
    }
  }
  updateTag(tag: string, ev: any) {
    const tags = [];
    /*this.tags.forEach(t => {
      if (t.exists) {
        tags.push(t.name + '_yes');
      }
      if (t.notExists) {
        tags.push(t.name + '_no');
      }
    });*/
    this.$store.state.tileTags[this.tile] = this.selectedTags;
    this.$store.dispatch('setTileTags', this.$store.state.tileTags);
    API.setTileTags(this.tile, this.selectedTags);
    this.$emit('onTagsSet', this.tile, this.selectedTags.length > 0);
  }
  addItem() {
    const tag = prompt('Please enter a tag');
    //this.tags.push(tag);
    //API.saveTags(this.tags);
    const subtag = {
      name: tag,
      color:
        '#' +
        Math.round(Math.random() * 255)
          .toString(16)
          .padStart(2, '0') +
        Math.round(Math.random() * 255)
          .toString(16)
          .padStart(2, '0') +
        Math.round(Math.random() * 255)
          .toString(16)
          .padStart(2, '0')
    };
    if (this.tags[tag] == null) {
      this.tags[tag] = subtag;
      this.tagKeys.push(tag);
      API.addTag(tag, subtag).then(success => {
        this.$store.dispatch('setTags', this.tags);
      });
    }
  }
}
