import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showGlobalLoader: false,
    tileTags: null,
    selectedTag: null,
    tags: null,
    lockedTiles: null,
    finishedTiles: null,
    approvedTiles: null,
    processedTiles: null,
    user: null,
    status: null,
    tileStatus: {}
  },
  mutations: {
    showGlobalLoader(state, payload) {
      state.showGlobalLoader = payload;
    },
    setStatus(state, status) {
      state.status = status;
    },
    setSelectedTag(state, tag) {
      state.selectedTag = tag;
    },
    setTileTags(state, tags) {
      state.tileTags = tags;
    },
    setTags(state, tags) {
      state.tags = tags;
    },
    setUser(state, user) {
      state.user = user;
    },
    setLockedTiles(state, tiles) {
      state.lockedTiles = tiles;
    },
    setFinishedTiles(state, tiles) {
      state.finishedTiles = tiles;
    },
    setApprovedTiles(state, tiles) {
      state.approvedTiles = tiles;
    },
    setProcessedTiles(state, tiles) {
      state.processedTiles = tiles;
    },
    setTileStatus(state, status) {
      state.tileStatus = status;
    }
  },
  actions: {
    showGlobalLoader({ commit }, isShow) {
      commit('showGlobalLoader', isShow);
    },
    setStatus({ commit }, status) {
      commit('setStatus', status);
    },
    setTileTags({ commit }, tags) {
      commit('setTileTags', tags);
    },
    setSelectedTag({ commit }, tag) {
      commit('setSelectedTag', tag);
    },
    setTags({ commit }, tags) {
      commit('setTags', tags);
    },
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setLockedTiles({ commit }, tiles) {
      commit('setLockedTiles', tiles);
    },
    setFinishedTiles({ commit }, tiles) {
      commit('setFinishedTiles', tiles);
    },
    setApprovedTiles({ commit }, tiles) {
      commit('setApprovedTiles', tiles);
    },
    setProcessedTiles({ commit }, tiles) {
      commit('setProcessedTiles', tiles);
    },
    setTileStatus({ commit }, status) {
      commit('setTileStatus', status);
    }
  },
  modules: {}
});
