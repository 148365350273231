
interface ColorType {
  red: number;
  green: number;
  blue: number;
  opacity: number;
}
interface LableType {
  name: string;
  color: string; //ColorType;
  index: number;
}
interface Brush {
  text: string;
}

//import Mapbox from 'mapbox-gl-vue';
import mapboxgl, { MapMouseEvent, MapboxEvent } from 'mapbox-gl';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Unit } from '@/interfaces/unit';
import { Farm } from '@/interfaces/farm';
import { Parcel } from '@/interfaces/parcel';
import { Survey } from '@/interfaces/survey';
import Intersect from '@turf/intersect';
import { LngLat, Marker, LngLatBoundsLike, GeoJSONSource, GeoJSONSourceOptions } from 'mapbox-gl';
import LabelEditor from '@/components/LabelEditor.vue';
import { Tile } from '../interfaces/tile';
import API from '@/services/api';
import { Polygon, Feature } from 'geojson';
@Component({
  components: {
    //Mapbox,
    LabelEditor
  }
})
export default class EditorMap extends Vue {
  private map: mapboxgl.Map;
  private BRUSHES = {
    CLASSIC: 'Classic',
    ON_VOID: 'On Void',
    FLOOD: 'Flood',
    FLOOD_BY_COLOR: 'Flood By Color',
    ERASE: 'Erase',
    ERASE_ACTIVE: 'Erase active'
  };

  tileSelected: Tile;
  unitMarkers = new Array<Marker>();
  farmMarkers = {};
  CANVAS_SIZE = 1024;
  TILE_SIZE = 256;
  index = 0;
  imgs: Map<string, any>;
  survey: string;
  saving = false;
  viewOnly = false;
  curatedImage: any;
  isDirty = false;
  startPoint: any;
  imgCopy = [];
  elementSize = 1;
  X: number;
  Y: number;
  shifting = 0;
  bgCtx: CanvasRenderingContext2D;
  ctx: CanvasRenderingContext2D;
  imageDataCopy: ImageData;
  labels: LableType[] = [];
  selectedLabelControl: string;
  activeLabel: LableType;
  prevLabel: LableType;
  brushes: Brush[] = [
    { text: this.BRUSHES.CLASSIC },
    { text: this.BRUSHES.ON_VOID },
    { text: this.BRUSHES.FLOOD },
    { text: this.BRUSHES.FLOOD_BY_COLOR },
    { text: this.BRUSHES.ERASE },
    { text: this.BRUSHES.ERASE_ACTIVE }
  ];
  existingTags: any;
  activeBrush: Brush;
  lastX: number;
  lastY: number;
  scaleFactor: number;
  transparency: number;
  undoItems: any[];
  items: any[];
  mode: string;
  dragStart: DOMPoint;
  dragging = false;
  itemIdx = 0;
  Top: number;
  Left: number;
  cursorSize: number;
  MAX_CURSOR_SIZE: number;
  //@Prop()
  tile: string;
  cursors: string[];
  constructor() {
    super();
    this.MAX_CURSOR_SIZE = 60;
    this.imageDataCopy = null;
    this.tileSelected = null;
    this.tile = null;
    this.curatedImage = null;
    this.existingTags = null;
    this.imgs = new Map<string, any>();
    this.items = [];
    this.transparency = 0;

    this.cursors = [];

    this.labels = [];

    this.activeBrush = this.brushes[0];
    this.activeLabel = null;
    this.prevLabel = null;
    this.selectedLabelControl = null;
    this.items = [];
    this.scaleFactor = 1.1;
    this.mode = 'drawing';
    this.undoItems = [];
    this.cursorSize = 6;
  }
  generateCursors() {
    const cnvs = document.createElement('canvas');
    for (let i = 0; i <= this.MAX_CURSOR_SIZE; i++) {
      cnvs.setAttribute('width', '' + (i * 2 + 2));
      cnvs.setAttribute('height', '' + (i * 2 + 2));
      const ctx = cnvs.getContext('2d');
      ctx.clearRect(0, 0, i * 2 + 2, i * 2 + 2);
      ctx.fillStyle = '#000';
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.arc(i + 1, i + 1, i, 0, 2 * Math.PI);
      ctx.stroke();
      this.cursors.push(cnvs.toDataURL());
    }
  }
  mounted() {
    this.generateCursors();
    mapboxgl.accessToken =
      'pk.eyJ1Ijoia2lycnVraXJydSIsImEiOiJjazJhMmJ6anMxMGh5M21tczJ6NTEwaW4yIn0.irfuud6XtRKV6K7hSv-bkQ';
    this.map = new mapboxgl.Map({
      container: 'map',
      zoom: 1,
      minZoom: 1,
      maxZoom: 24,
      preserveDrawingBuffer: true,
      style: 'mapbox://styles/mapbox/satellite-v9'
    });
    this.map.on('load', () => {
      this.onMapLoaded();
      this.attachMapEvents();
    });
    this.bgCtx = (document.querySelector('#bgCanvas') as HTMLCanvasElement).getContext('2d');
    this.ctx = (document.querySelector('#drawCanvas') as HTMLCanvasElement).getContext('2d');
    this.ctx.imageSmoothingEnabled = false;
    //this.drawBackground();
  }

  onMapLoaded() {
    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disable();
  }
  selectTileForLabelling(tileId: string, viewOnly = false) {
    this.labels = [];
    this.elementSize = 1;
    this.imageDataCopy = null;
    this.shifting = 0;
    //for (const tag in this.$store.state.tags) {
    if (this.$store.state.tileTags[tileId].indexOf('Pixel-Tag') >= 0) {
      this.$store.state.tags['Pixel-Tag'].map((x) => {
        this.labels.push({ name: x.name, color: x.color, index: 0 });
      });
    }
    //}
    if (this.labels.length == 0) {
      alert('This tile has no Pixel-Tag. Can not label.');
      return;
    }
    this.viewOnly = viewOnly;
    this.activeLabel = this.labels[0];
    this.selectedLabelControl = this.activeLabel.name;
    if (this.$store.state.lockedTiles[tileId] == null) this.$store.state.lockedTiles[tileId] = [];
    if (this.$store.state.lockedTiles[tileId].indexOf(tileId) < 0) this.$store.state.lockedTiles[tileId].push(tileId);
    this.map.getCanvas().style.cursor = 'url(' + this.cursors[this.cursorSize] + '),default';
    this.tile = tileId;
    const pts = this.tile.split('_');
    this.survey = pts[0];
    const x = parseInt(pts[1]);
    const y = parseInt(pts[2]);
    this.X = x;
    this.Y = y;
    this.drawBackground();
    if (!viewOnly) {
      this.$emit('onTileSelected', tileId);
    } else {
      this.mode = 'drawing';
    }
  }
  attachCanvas() {
    const { lat: top, lng: left, zoom: z } = this.getLatLngFromTile(this.X, this.Y, 20);
    const { lat: bottom, lng: right, zoom: z1 } = this.getLatLngFromTile(this.X + 1, this.Y + 1, 20);

    this.Top = top;
    this.Left = left;
    const tileBounds = [
      [left, top],
      [right, top],
      [right, bottom],
      [left, bottom]
    ];
    this.map.fitBounds([left, bottom, right, top], { animate: false });

    const tl = { lat: top, lng: left };
    const br = { lat: bottom, lng: right };
    const coords = [
      [
        [tl.lng, tl.lat],
        [tl.lng, br.lat],
        [br.lng, br.lat],
        [br.lng, tl.lat],
        [tl.lng, tl.lat]
      ]
    ];
    this.map.fitBounds([tl.lng, br.lat, br.lng, tl.lat], { animate: false });
    const featPoly: Polygon = { type: 'Polygon', coordinates: coords };
    const borderPoly: Feature = { type: 'Feature', properties: {}, geometry: featPoly };
    if (this.map.getLayer('border') != null) {
      this.map.removeLayer('border');
      this.map.removeSource('border');
      this.map.removeLayer('bg-canvas-layer');
      this.map.removeSource('bg-canvas-source');
      this.map.removeLayer('draw-canvas-layer');
      this.map.removeSource('draw-canvas-source');
    }
    this.map.addLayer({
      id: 'border',
      type: 'line',
      minzoom: 16,
      source: {
        type: 'geojson',
        data: { type: 'FeatureCollection', features: [borderPoly] }
      },
      paint: {
        'line-color': 'rgba(200, 200, 0, 1.0)',
        'line-width': 2
      }
    });
    this.map.addSource('bg-canvas-source', {
      type: 'canvas',
      canvas: 'bgCanvas',
      coordinates: tileBounds,
      animate: true
    });
    this.map.addLayer({
      id: 'bg-canvas-layer',
      type: 'raster',
      source: 'bg-canvas-source',
      paint: {
        'raster-resampling': 'nearest'
      }
    });

    this.map.addSource('draw-canvas-source', {
      type: 'canvas',
      canvas: 'drawCanvas',
      coordinates: tileBounds,
      animate: true
    });
    this.map.addLayer({
      id: 'draw-canvas-layer',
      type: 'raster',
      source: 'draw-canvas-source',
      paint: {
        'raster-resampling': 'nearest'
      }
    });

    this.map.boxZoom.disable();
    this.map.dragPan.disable();
    this.map.scrollZoom.disable();

    this.map.doubleClickZoom.disable();
    this.drawExistingLabels();
  }

  async drawExistingLabels() {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    this.existingTags = null;
    img.onload = (ev) => {
      this.existingTags = img;
      this.ctx.drawImage(img, 0, 0, img.width, img.height);
    };
    img.src = await API.getTaggedImage(this.tile, this.$store.state.user.UserInfo.id);
  }

  attachMapEvents() {
    this.map.on('mousedown', (ev: MapMouseEvent) => {
      if (this.tile == null) {
        return;
      }
      this.dragging = true;
      this.index++;
      this.startPoint = this.getPointOnCanvas(ev);
    });
    this.map.on('mouseup', () => {
      this.dragging = false;
    });
    this.map.on('click', (ev: MapMouseEvent) => {
      if (this.tile == null) {
        return;
      }
      this.index++;
      if (
        this.mode === 'drawing' &&
        (this.activeBrush.text === this.BRUSHES.FLOOD || this.activeBrush.text === this.BRUSHES.FLOOD_BY_COLOR)
      ) {
        const pt = this.getPointOnCanvas(ev);
        this.drawOnCanvas({ x: pt.x, y: pt.y });
      }
    });
    this.map.on('mousemove', (ev: MapMouseEvent) => {
      if (this.tile == null) {
        return;
      }
      if (this.dragging && this.mode == 'drawing') {
        const pt = this.getPointOnCanvas(ev);
        this.drawOnCanvas({ x: pt.x, y: pt.y });
      }
      if (this.activeBrush.text === this.BRUSHES.CLASSIC) {
        //this.points.push(pt);
      }
    });
    this.map.getCanvas().addEventListener('mousewheel', (ev: Event) => {
      if (this.tile == null) {
        return false;
      }
      const evt = ev as WheelEvent;
      if (this.mode === 'drawing') {
        const delta = evt.deltaY ? evt.deltaY / 40 : evt.detail ? -evt.detail : 0;
        this.cursorSize += delta > 0 ? 1 : -1;
        if (this.cursorSize < 1) {
          this.cursorSize = 1;
        }
        if (this.cursorSize > this.MAX_CURSOR_SIZE) {
          this.cursorSize = this.MAX_CURSOR_SIZE;
        }
        (evt.target as HTMLElement).style.cursor = 'url(' + this.cursors[this.cursorSize] + '),default';
      }
    });
    window.addEventListener('keypress', (evt: KeyboardEvent) => {
      if (this.tile == null || this.tile.length === 0) {
        return;
      }
      if (evt.shiftKey && evt.code === 'KeyQ') {
        this.transparency = 0;
        this.onTransparencyChanged();
      } else if (evt.shiftKey && evt.code === 'Digit2') {
        this.transparency = 50;
        this.onTransparencyChanged();
      } else if (evt.shiftKey && evt.code === 'KeyW') {
        this.transparency = 100;
        this.onTransparencyChanged();
      }
      const brushesKey = {
        KeyA: this.BRUSHES.CLASSIC,
        KeyS: this.BRUSHES.ON_VOID,
        KeyD: this.BRUSHES.FLOOD,
        KeyF: this.BRUSHES.FLOOD_BY_COLOR,
        KeyG: this.BRUSHES.ERASE,
        KeyH: this.BRUSHES.ERASE_ACTIVE
      };
      if (brushesKey[evt.code]) {
        const brush = this.brushes.find((brush) => brush.text === brushesKey[evt.code]);
        if (brush) {
          this.activeBrush = brush;
        }
      }
      if (evt.code === 'KeyZ') {
        this.undo();
      }
      if (evt.code === 'KeyY') {
        this.redo();
      }
      if (!evt.shiftKey && evt.code === 'KeyW') {
        this.transparency += 15;
        if (this.transparency > 100) {
          this.transparency = 100;
        }
        this.onTransparencyChanged();
      }
      if (!evt.shiftKey && evt.code === 'KeyQ') {
        this.transparency -= 15;
        if (this.transparency < 0) {
          this.transparency = 0;
        }
        this.onTransparencyChanged();
      }
      if (!evt.shiftKey && evt.code.indexOf('Digit') !== -1) {
        const index = parseInt(evt.key);
        if (index && this.labels[index - 1]) {
          const name = this.labels[index - 1].name;
          this.selectLabel(name);
          this.selectedLabelControl = name;
        }
      }
      if (evt.code === 'KeyR' && this.prevLabel) {
        this.selectedLabelControl = this.prevLabel.name;
        this.selectLabel(this.prevLabel.name);
      }
      if (evt.code === 'Space') {
        this.mode = this.mode === 'drawing' ? 'zooming' : 'drawing';
      }
      if (this.mode === 'zooming') {
        this.map.boxZoom.enable();
        this.map.dragPan.enable();
        this.map.scrollZoom.enable();
        this.map.doubleClickZoom.enable();
        this.map.getCanvas().style.cursor = 'move';
      } else {
        this.map.boxZoom.disable();
        this.map.dragPan.disable();
        this.map.scrollZoom.disable();
        this.map.doubleClickZoom.disable();
        this.map.getCanvas().style.cursor = 'url(' + this.cursors[this.cursorSize] + '),default';
      }
    });
  }
  getPointOnCanvas(ev): { x: number; y: number } {
    const origin = this.map.project([this.Left, this.Top]);
    return {
      x: Math.round((ev.point.x - origin.x) * Math.pow(2, 21 - this.map.getZoom())),
      y: Math.round((ev.point.y - origin.y) * Math.pow(2, 21 - this.map.getZoom()))
    };
  }
  drawBackground() {
    for (let x = 0; x < this.CANVAS_SIZE / this.TILE_SIZE; x++) {
      for (let y = 0; y < this.CANVAS_SIZE / this.TILE_SIZE; y++) {
        if (this.imgs.has('' + x + '_' + y)) {
          const img = this.imgs.get('' + x + '_' + y);
          if (img != null) {
            this.bgCtx.drawImage(img, x * this.TILE_SIZE, y * this.TILE_SIZE, this.TILE_SIZE, this.TILE_SIZE);
          }
        } else {
          const image = new Image();
          image.crossOrigin = 'Anonymous';
          image.src =
            'https://storage.googleapis.com/drone-tiles-' +
            process.env.VUE_APP_ENV +
            '/' +
            this.survey +
            '/22/' +
            (this.X * 4 + x) +
            '/' +
            (this.Y * 4 + y) +
            '.png';
          image.onerror = () => {
            this.imgs.set('' + x + '_' + y, null);
            if (this.imgs.size == ((this.CANVAS_SIZE / this.TILE_SIZE) * this.CANVAS_SIZE) / this.TILE_SIZE) {
              this.attachCanvas();
            }
          };
          image.onload = () => {
            this.imgs.set('' + x + '_' + y, image);
            this.bgCtx.drawImage(image, x * this.TILE_SIZE, y * this.TILE_SIZE, this.TILE_SIZE, this.TILE_SIZE);
            if (this.imgs.size == ((this.CANVAS_SIZE / this.TILE_SIZE) * this.CANVAS_SIZE) / this.TILE_SIZE) {
              this.attachCanvas();
            }
          };
        }
      }
    }
  }
  getLatLngFromTile(x: number, y: number, z: number): { lat: number; lng: number; zoom: number } {
    const lng = (x / Math.pow(2, z)) * 360 - 180;

    const n = Math.PI - (2 * Math.PI * y) / Math.pow(2, z);
    const lat = (180 / Math.PI) * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n)));
    return { lat: lat, lng: lng, zoom: z };
  }
  getAsRGB(clr: ColorType): string {
    return `#${clr.red.toString(16).padStart(2, '0')}${clr.green.toString(16).padStart(2, '0')}${clr.blue
      .toString(16)
      .padStart(2, '0')}`;
  }

  undo(): void {
    if (this.items.length > 0) {
      const lastItem = this.items.pop();
      this.undoItems.push(lastItem);
      while (this.items.length > 0 && this.items[this.items.length - 1].index === lastItem.index) {
        this.undoItems.push(this.items.pop());
      }

      this.redraw();
    }
  }
  redo(): void {
    if (this.undoItems.length > 0) {
      const lastItem = this.undoItems.pop();
      this.items.push(lastItem);
      while (this.undoItems.length > 0 && this.undoItems[this.undoItems.length - 1].index === lastItem.index) {
        this.items.push(this.undoItems.pop());
      }
      this.redraw();
    }
  }

  drawOnCanvas(currentPos: { x: number; y: number }): void {
    if (!this.ctx) {
      return;
    }
    this.isDirty = true;
    this.$emit('modified', this.isDirty);
    if (currentPos) {
      const item = {
        index: this.index,
        action: this.activeBrush.text,
        point: currentPos,
        width: this.cursorSize,
        color: this.activeLabel.color,
        startPoint: this.startPoint
      };
      this.startPoint = currentPos;
      this.items.push(item);
      this.drawItem(item);
    }
  }

  generateStructureElement(size: number) {
    const res = [];
    for (let i = 0; i < size; i++) {
      res[i] = [];

      for (let j = 0; j < size; j++) {
        res[i][j] = 1;
      }
    }
    return res;
  }
  //https://opencv-python-tutroals.readthedocs.io/en/latest/py_tutorials/py_imgproc/py_morphological_ops/py_morphological_ops.html
  //https://github.com/DanielRapp/morph
  erode(img: [][], structElem: any): [][] {
    let res = [];
    for (let i = 0; i < img.length; i++) {
      res[i] = [];

      for (let j = 0; j < img[0].length; j++) {
        let areEqual = 1,
          size = structElem.length;
        for (let sI = -Math.floor(size / 2); sI < Math.ceil(size / 2); sI++) {
          for (let sJ = -Math.floor(size / 2); sJ < Math.ceil(size / 2); sJ++) {
            let pixel = null;
            if (i + sI < 0 || i + sI >= img.length || j + sJ < 0 || j + sJ >= img[0].length) pixel = 1;
            else pixel = img[i + sI][j + sJ];
            if (pixel != structElem[Math.floor(size / 2) + sI][Math.floor(size / 2) + sJ]) {
              areEqual = 0;
            }
          }
        }
        res[i][j] = areEqual;
      }
    }
    return res;
  }
  complement(img: [][]): [][] {
    let res = [];
    for (let i = 0; i < img.length; i++) {
      res[i] = [];
      for (let j = 0; j < img[0].length; j++) {
        res[i][j] = Math.abs(img[i][j] - 1);
      }
    }
    return res;
  }
  dilate(img: [][], structElem: any): [][] {
    return this.complement(this.erode(this.complement(img), structElem));
  }

  open(img: [][], structElem: any): [][] {
    return this.dilate(this.erode(img, structElem), structElem);
  }

  close(img: [][], structElem: any): [][] {
    return this.erode(this.dilate(img, structElem), structElem);
  }

  redraw(withBg = true): void {
    // Clear the entire canvas
    this.clearCanvas(this.bgCtx);
    this.clearCanvas(this.ctx);
    if (withBg) {
      this.drawBackground();
    }

    this.ctx.globalAlpha = (100 - this.transparency) / 100;
    this.itemIdx = 0;
    if (this.existingTags != null) {
      this.ctx.drawImage(this.existingTags, 0, 0, this.existingTags.width, this.existingTags.height);
    } else if (this.curatedImage != null) {
      this.ctx.drawImage(this.curatedImage, 0, 0, this.CANVAS_SIZE + 1, this.CANVAS_SIZE + 1);
    }
    this.drawItems();
  }
  drawItems(): void {
    if (this.itemIdx >= this.items.length) {
      return;
    }
    for (let i = this.itemIdx; i < Math.min(this.itemIdx + 10, this.items.length); i++, this.itemIdx++) {
      const item = this.items[i];
      this.drawItem(item, true);
    }
    requestAnimationFrame(this.drawItems.bind(this));
  }
  clearCanvas(ctx: CanvasRenderingContext2D): void {
    const p1 = this.transformedPoint(0, 0);
    const p2 = this.transformedPoint(this.CANVAS_SIZE + 1, this.CANVAS_SIZE + 1);
    ctx.clearRect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y);
    ctx.save();
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    ctx.clearRect(0, 0, this.CANVAS_SIZE + 1, this.CANVAS_SIZE + 1);
    ctx.restore();
  }

  restore() {
    if (confirm('Are you sure you want to restore to original state?')) {
      this.items = [];
      this.imgs = new Map<string, any>();
      this.shifting = 0;
      this.elementSize = 1;
      this.imageDataCopy = null;
      this.clearCanvas(this.bgCtx);
      this.clearCanvas(this.ctx);
      this.selectTileForLabelling(this.tile);
    }
  }

  rgbArray(rgb: string): Uint8ClampedArray {
    const clr = new Uint8ClampedArray(4);
    clr[0] = parseInt(rgb.substr(1, 2), 16);
    clr[1] = parseInt(rgb.substr(3, 2), 16);
    clr[2] = parseInt(rgb.substr(5, 2), 16);
    clr[3] = (255 * (100 - this.transparency)) / 100;
    return clr;
  }
  drawItem(item: any, isRedraw = false): void {
    this.ctx.beginPath();

    let pos = item.point;
    let scaledWidth = item.width;
    switch (item.action) {
      case this.BRUSHES.FLOOD:
        this.floodFill(this.ctx, pos.x, pos.y, this.rgbArray(item.color), 4);
        break;
      case this.BRUSHES.FLOOD_BY_COLOR:
        {
          const fillColorBuf = this.rgbArray(item.color);
          const fillColor =
            fillColorBuf[3] * 16777216 + fillColorBuf[2] * 65536 + fillColorBuf[1] * 256 + fillColorBuf[0];
          this.floodFillByColor(this.ctx, pos.x, pos.y, fillColor);
        }
        break;
      case this.BRUSHES.ON_VOID:
        {
          if (isRedraw) {
            scaledWidth *= this.ctx.getTransform().a;
          }
          pos = this.transformedPoint(item.point.x, item.point.y);
          const p1 = this.transformedPoint(item.startPoint.x, item.startPoint.y);
          const clr = this.intColor(item.color);
          this.drawConnectedLine(p1, pos, 0, clr, Math.round(scaledWidth));
        }
        break;
      case this.BRUSHES.ERASE:
        this.drawConnectedLine(item.startPoint, pos, 0, null, scaledWidth);
        break;
      case this.BRUSHES.ERASE_ACTIVE:
        {
          if (isRedraw) {
            scaledWidth *= this.ctx.getTransform().a;
          }
          const clr = this.intColor(item.color);
          this.drawConnectedLine(item.startPoint, pos, clr, 0, scaledWidth);
        }
        break;
      default:
        {
          const clr = this.intColor(item.color);
          this.drawConnectedLine(item.startPoint, pos, clr, null, scaledWidth);
        }
        break;
    }
    this.ctx.closePath();
  }

  private drawConnectedLine(p1, p2, clr, target, scaledWidth) {
    const connectedPos = this.getConnectedPos(p1, p2);
    for (let i = 0; i < connectedPos.length; i++) {
      this.setCanvasColor(connectedPos[i], scaledWidth, clr, target);
    }
    this.setCanvasColor(p2, scaledWidth, clr, target);
  }

  getConnectedPos(p1, p2): DOMPoint[] {
    const pos = [];
    if (p1.x == p2.x && p1.y == p2.y) {
      return [];
    }

    const minX = Math.min(p1.x, p2.x);
    const maxX = Math.max(p1.x, p2.x);
    const minY = Math.min(p1.y, p2.y);
    const maxY = Math.max(p1.y, p2.y);

    if (p1.x != p2.x) {
      const slope = Math.atan2(p2.y - p1.y, p2.x - p1.x);
      const c = p1.y - Math.tan(slope) * p1.x;
      if (maxY - minY < maxX - minX) {
        for (let x = minX; x < maxX; x++) {
          const y = x * Math.tan(slope) + c;
          pos.push(new DOMPoint(x, y));
        }
      } else {
        for (let y = minY; y < maxY; y++) {
          const x = (y - c) / Math.tan(slope);
          pos.push(new DOMPoint(x, y));
        }
      }
    } else {
      for (let y = minY; y < maxY; y++) {
        pos.push(new DOMPoint(p1.x, y));
      }
    }
    return pos;
  }

  intColor(rgb: string): number {
    const arr = this.rgbArray(rgb);
    return arr[3] * 16777216 + arr[2] * 65536 + arr[1] * 256 + arr[0];
  }
  setCanvasColor(pos: DOMPoint, width: number, clr: number, target: number): void {
    const rad = width;
    const imgData = this.ctx.getImageData(pos.x, pos.y, rad * 2, rad * 2);
    const data = new Uint32Array(imgData.data.buffer);
    const radSquare = rad * rad;
    let pix = 0;
    for (let j = -rad; j < rad; j += 1) {
      for (let k = -rad; k < rad; k += 1, pix += 1) {
        if (j * j + k * k <= radSquare) {
          if (target == null) {
            data[pix] = clr;
          } else {
            const rgb = data[pix];
            if (this.sameColors(rgb, clr)) {
              data[pix] = target;
            }
          }
        }
      }
    }
    this.ctx.putImageData(imgData, pos.x, pos.y);
  }
  getPixel(imageData: { width: number; height: number; data: Uint32Array }, x: number, y: number): number {
    //const offset = (y * imageData.width + x) * 4;
    return imageData.data[y * imageData.width + x]; // imageData.data.slice(offset, offset + 4);
  }

  setPixel(imageData: ImageData, x: number, y: number, color: Uint8ClampedArray): void {
    const offset = (y * imageData.width + x) * 4;
    imageData.data[offset + 0] = color[0];
    imageData.data[offset + 1] = color[1];
    imageData.data[offset + 2] = color[2];
    imageData.data[offset + 3] = color[3];
  }

  colorsMatch(a: Uint8ClampedArray, b: Uint8ClampedArray, rangeSq: number): boolean {
    const dr = a[0] - b[0];
    const dg = a[1] - b[1];
    const db = a[2] - b[2];
    const da = a[3] - b[3];
    return dr * dr + dg * dg + db * db + da * da < rangeSq;
  }
  floodFillByColor(ctx: CanvasRenderingContext2D, xt: number, yt: number, fillColor: number): void {
    // fillColor: Uint8ClampedArray): void {
    const start = new Date().getTime();
    const target = new Uint32Array(this.bgCtx.getImageData(xt, yt, 1, 1).data.buffer)[0];
    const bgImageData = this.bgCtx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height).data;
    const bgImgData = new Uint32Array(bgImageData.buffer);
    const imageData = this.ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
    const pixelData = new Uint32Array(imageData.data.buffer);
    for (let x = 0; x < ctx.canvas.width; x++) {
      for (let y = 0; y < ctx.canvas.height; y++) {
        const pos = y * imageData.width + x; // * 4;
        const clr = bgImgData[pos];
        if (pixelData[pos] == 0 && this.sameColors(clr, target)) {
          //this.setPixel(imageData, x, y, fillColor);
          pixelData[pos] = fillColor;
        }
      }
    }
    ctx.putImageData(imageData, 0, 0);
  }
  sameColors(currentColor, fillColor, factor = 40): boolean {
    if (currentColor == fillColor) {
      return true;
    }
    if (currentColor == 0 && fillColor != 0) return false;
    const cr = (currentColor >>> 16) & 0xff;
    const cg = (currentColor >>> 8) & 0xff;
    const cb = currentColor & 0xff;
    const fr = (fillColor >>> 16) & 0xff;
    const fg = (fillColor >>> 8) & 0xff;
    const fb = fillColor & 0xff;
    const coeff = Math.sqrt((cr - fr) * (cr - fr) + (cg - fg) * (cg - fg) + (cb - fb) * (cb - fb));
    return coeff < factor;
  }
  floodFill(
    ctx: CanvasRenderingContext2D,
    xa: number,
    ya: number,
    fillColorBuf: Uint8ClampedArray,
    width: number,
    range = 1
  ): void {
    // read the pixels in the canvas
    const imageData = ctx.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
    const pixelData = {
      width: imageData.width,
      height: imageData.height,
      data: new Uint32Array(imageData.data.buffer)
    };

    const fillColor = fillColorBuf[3] * 16777216 + fillColorBuf[2] * 65536 + fillColorBuf[1] * 256 + fillColorBuf[0];
    // flags for if we visited a pixel already
    const visited = new Uint8Array(imageData.width * imageData.height);

    // get the color we're filling
    const targetColor = this.getPixel(pixelData, xa, ya);

    // check we are actually filling a different color
    if (targetColor != fillColor) {
      // this.colorsMatch(targetColor, fillColor, 1)) {
      const rangeSq = range * range;
      const pixelsToCheck = [xa, ya];
      while (pixelsToCheck.length > 0) {
        const y = pixelsToCheck.pop();
        const x = pixelsToCheck.pop();

        const currentColor = this.getPixel(pixelData, x, y);
        if (targetColor == 0 || this.sameColors(targetColor, currentColor)) {
          if (!this.sameColors(currentColor, fillColor)) {
            pixelData.data[y * pixelData.width + x] = fillColor;
            //visited[y * imageData.width + x] = 1; // mark we were here already
            if (x < this.CANVAS_SIZE) pixelsToCheck.push(x + 1, y);
            if (x > 0) pixelsToCheck.push(x - 1, y);
            if (y < this.CANVAS_SIZE) pixelsToCheck.push(x, y + 1);
            if (y > 0) pixelsToCheck.push(x, y - 1);
          }
        }
      }
      // put the data back
      ctx.putImageData(imageData, 0, 0);
    }
  }

  transformedPointInv(x: number, y: number): DOMPoint {
    const pt = { x, y } as DOMPoint;
    return this.ctx.getTransform().inverse().transformPoint(pt);
  }

  transformedPoint(x: number, y: number): DOMPoint {
    const pt = { x, y } as DOMPoint;
    return this.ctx.getTransform().transformPoint(pt);
  }
  curatedWeeds(): void {
    if (!confirm('Are you sure you want to add weeds from curated data?')) return;
    this.curatedImage = new Image();
    this.curatedImage.crossOrigin = 'Anonymous';
    this.curatedImage.onload = (ev: Event) => {
      this.ctx.drawImage(this.curatedImage, 0, 0, this.CANVAS_SIZE, this.CANVAS_SIZE);
    };
    this.curatedImage.onerror = (ev: Event) => {
      alert('Curated weeds not available.');
      this.curatedImage = null;
    };
    this.curatedImage.src =
      process.env.VUE_APP_LABELLING_SERVER +
      '/curated-weeds?id=' +
      this.tile +
      '&user=' +
      this.$store.state.user.UserInfo.id +
      '&clr=' +
      encodeURIComponent(this.activeLabel.color);
  }
  unlock(): void {
    if (confirm('Are you sure you want to cancel labelling?')) {
      API.unlockTile(this.tile, this.$store.state.user.UserInfo.id).then((res) => {
        this.tile = null;
        this.clearCanvas(this.bgCtx);
        this.clearCanvas(this.ctx);
        this.$emit('onTileUnlocked');
      });
    }
  }
  getImage(): [][] {
    const img = [];
    const imageData = this.ctx.getImageData(0, 0, this.CANVAS_SIZE, this.CANVAS_SIZE);
    const pixelData = {
      width: imageData.width,
      height: imageData.height,
      data: new Uint32Array(imageData.data.buffer)
    };
    this.imgCopy = [];
    for (let x = 0; x < imageData.width; x++) {
      img[x] = [];
      this.imgCopy[x] = [];
    }
    const fillColorBuf = this.rgbArray(this.activeLabel.color);
    const activeLabelcolor =
      fillColorBuf[3] * 16777216 + fillColorBuf[2] * 65536 + fillColorBuf[1] * 256 + fillColorBuf[0];

    for (let x = 0; x < imageData.width; x++) {
      for (let y = 0; y < imageData.height; y++) {
        const clr = this.getPixel(pixelData, x, y);
        if (this.sameColors(clr, activeLabelcolor)) {
          img[x][y] = 1;
          this.imgCopy[x][y] = 1;
        } else {
          img[x][y] = 0;
          this.imgCopy[x][y] = 0;
        }
      }
    }
    return img;
  }
  setImage(img: [][]): void {
    const imageData = this.ctx.getImageData(0, 0, this.CANVAS_SIZE, this.CANVAS_SIZE);
    const pixelData = new Uint32Array(imageData.data.buffer);
    const fillColorBuf = this.rgbArray(this.activeLabel.color);
    const activeLabelcolor =
      fillColorBuf[3] * 16777216 + fillColorBuf[2] * 65536 + fillColorBuf[1] * 256 + fillColorBuf[0];
    for (let x = 1; x < imageData.width; x++) {
      for (let y = 1; y < imageData.height; y++) {
        const pos = y * imageData.width + x; // * 4;
        if (img[x][y] != 0 && this.imgCopy[x][y] == 0) {
          pixelData[pos] = activeLabelcolor;
        } else if (img[x][y] == 0 && this.imgCopy[x][y] == 1) {
          if (x > 0) pixelData[pos] = pixelData[pos - 1];
        }
      }
    }
    this.ctx.putImageData(imageData, 0, 0);
    this.existingTags.onload = () => {
      this.redraw();
    };
    this.existingTags.src = (document.querySelector('#drawCanvas') as HTMLCanvasElement).toDataURL('image/png', 100);
  }
  applyOpen() {
    const transparency = this.transparency;
    this.transparency = 0;
    this.redraw();

    if (this.imageDataCopy == null) {
      this.imageDataCopy = this.ctx.getImageData(0, 0, this.CANVAS_SIZE, this.CANVAS_SIZE);
    } else {
      this.ctx.putImageData(this.imageDataCopy, 0, 0);
    }
    setTimeout(() => {
      const se = this.generateStructureElement(this.elementSize);
      let img = this.getImage();
      img = this.dilate(img, se);
      img = this.erode(img, se);
      this.transparency = transparency;
      this.setImage(img);
    }, 200);
  }

  applyClose() {
    const transparency = this.transparency;
    this.transparency = 0;
    this.redraw();
    setTimeout(() => {
      const se = this.generateStructureElement(this.elementSize);
      let img = this.getImage();
      img = this.erode(img, se);
      img = this.dilate(img, se);
      this.transparency = transparency;
      this.setImage(img);
      this.elementSize += 2;
    }, 200);
  }

  async save() {
    this.saving = true;
    this.isDirty = false;
    this.$emit('modified', this.isDirty);
    this.ctx.save();
    this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    const prevTransparency = this.transparency;
    this.transparency = 0;
    this.redraw();
    const blob = await new Promise((resolve) =>
      (document.querySelector('#drawCanvas') as HTMLCanvasElement).toBlob(resolve)
    );
    const buffer = await new Response(blob as any).arrayBuffer();
    try {
      await API.saveLabels(
        this.survey + '_' + this.X + '_' + this.Y,
        this.$route.path == '/approve',
        this.$store.state.user.UserInfo.id,
        buffer
      ); //.then(success => {
    } catch (err) {
      alert('Error occurred while saving. \nTry logging in to auth.gamaya.com in another tab and save again.');
    } finally {
      this.transparency = prevTransparency;
      this.ctx.restore();
      this.redraw();
      this.saving = false;
    }
    //});
  }

  /////
  addUnit(unit: Unit) {
    const loc = new LngLat((unit.URLong + unit.LLLong) / 2, (unit.URLat + unit.LLLat) / 2);
    const marker = this.addMarker(loc, unit.Name);
    marker.getElement().addEventListener('click', () => {
      this.selectUnit(unit);
    });
    this.unitMarkers.push(marker);
  }
  addFarm(farm: Farm) {
    const loc = new LngLat((farm.URLong + farm.LLLong) / 2, (farm.URLat + farm.LLLat) / 2);
    const marker = this.addMarker(loc, farm.Name);
    marker.getElement().addEventListener('click', () => {
      this.selectFarm(farm);
    });
    this.farmMarkers[farm.id] = marker;
  }
  removeUnits() {
    this.removeMarkers(this.unitMarkers);
    this.unitMarkers = new Array<Marker>();
  }
  removeFarms() {
    this.removeMarkers(this.farmMarkers);
    this.farmMarkers = new Array<Marker>();
  }

  removeFarm(farm: Farm) {
    if (this.farmMarkers[farm.id] != null) {
      this.farmMarkers[farm.id].remove();
      delete this.farmMarkers[farm.id];
    }
  }

  removeMarkers(markers: any) {
    if (markers !== null) {
      //for (let i = arr.length - 1; i >= 0; i--) {
      for (const farm in markers) {
        markers[farm].remove();
      }
      markers = {};
      //}
    }
  }
  addMarker(loc: LngLat, title: string): Marker {
    const r = Math.round(Math.random() * 255);
    const g = Math.round(Math.random() * 255);
    const b = Math.round(Math.random() * 255);
    const marker = new Marker({ color: `rgb(${r}, ${g}, ${b})` }).setLngLat(loc).addTo(this.map);
    marker.getElement().setAttribute('title', title);
    return marker;
  }
  selectUnit(unit: Unit) {
    this.$emit('onUnitSelected', unit);
  }
  selectFarm(farm: Farm) {
    this.$emit('onFarmSelected', farm);
  }
  setBounds(bounds: LngLatBoundsLike) {
    this.map.fitBounds(bounds, { duration: 0 });
  }
  removeLayer(id) {
    if (this.map.getLayer(id) != null) {
      this.map.removeLayer(id);
      if (this.map.getSource(id) != null) this.map.removeSource(id);
    }
  }
  showParcel(parcel: Parcel) {
    this.removeLayer('parcel-' + parcel.id);
    this.map.addLayer({
      id: 'parcel-' + parcel.id,
      type: 'line',
      source: {
        type: 'geojson',
        data: parcel.Shape
      },
      paint: {
        'line-color': '#ffff00',
        'line-width': 2
      }
    });
  }
  showSurvey(survey: Survey, parcel: Parcel) {
    const parcelBounds = [parcel.LLLong, parcel.LLLat, parcel.URLong, parcel.URLat];
    this.removeLayer('survey-' + survey.id);
    this.map.addLayer(
      {
        id: 'survey-' + survey.id,
        type: 'raster',
        source: {
          type: 'raster',
          tiles: [`https://storage.googleapis.com/drone-tiles-${process.env.VUE_APP_ENV}/${survey.id}/{z}/{x}/{y}.png`],
          bounds: parcelBounds
        },
        paint: {
          'raster-resampling': 'nearest',
          'raster-opacity': 0.5
        }
      },
      'parcel-' + parcel.id
    );
  }
  showTaggedTiles(tiles: string[]) {
    tiles.map((t) => {
      const pts = t.split('_');
      const x = parseInt(pts[1]);
      const y = parseInt(pts[2]);
      const { lat: top, lng: left, zoom: z } = this.getLatLngFromTile(x, y, 20);
      const { lat: bottom, lng: right, zoom: z1 } = this.getLatLngFromTile(x + 1, y + 1, 20);
      const tl = { lat: top, lng: left };
      const br = { lat: bottom, lng: right };
      const coords = [
        [
          [tl.lng, tl.lat],
          [tl.lng, br.lat],
          [br.lng, br.lat],
          [br.lng, tl.lat],
          [tl.lng, tl.lat]
        ]
      ];

      const featPoly: Polygon = { type: 'Polygon', coordinates: coords };
      const borderPoly: Feature = {
        type: 'Feature',
        properties: { id: t, tags: this.$store.state.tileTags[t] },
        geometry: featPoly
      };
      if (this.isAvailableForLabelling(t)) {
        this.removeLayer('border-' + t);
        this.map.addLayer({
          id: 'border-' + t,
          type: 'fill',
          minzoom: 12,
          source: {
            type: 'geojson',
            data: { type: 'FeatureCollection', features: [borderPoly] }
          },
          paint: {
            'fill-color': 'rgba(0, 200, 200, 0.1)',
            'fill-outline-color': 'rgba(0, 200, 200, 1)'
          }
        });

        this.map.on('click', 'border-' + t, async (ev) => {
          this.lockTile(ev.features[0].properties.id);
        });
      }
    });
  }

  isAvailableForLabelling(id: string): boolean {
    if (
      this.$store.state.lockedTiles != null &&
      this.$store.state.lockedTiles[id] != null &&
      this.$store.state.lockedTiles[id].length > 0 &&
      this.$store.state.lockedTiles[id].indexOf(this.$store.state.user.UserInfo.id) < 0
    ) {
      return false;
    }
    return true;
  }

  async lockTile(tileID: string) {
    if (this.tile != null) {
      if (this.isDirty) {
        if (confirm('There are unsaved changes. Do you want to save them?')) {
          await this.save();
        }
      }
      this.clearCanvas(this.bgCtx);
      this.clearCanvas(this.ctx);
      this.items = [];
      this.existingTags = null;
      this.curatedImage = null;
      this.undoItems = [];
      if (this.map.getLayer('border-' + this.tile))
        this.map.setLayoutProperty('border-' + this.tile, 'visibility', 'visible');
      this.tile = null;
      this.imgs = new Map<string, any>();
    }
    if (this.map.getLayer('border-' + this.tile)) this.map.setLayoutProperty('border-' + tileID, 'visibility', 'none');
    if (
      (this.$store.state.lockedTiles[tileID] != null &&
        this.$store.state.lockedTiles[tileID].indexOf(this.$store.state.user.UserInfo.id) >= 0) ||
      this.$route.path == '/approve'
    ) {
      this.selectTileForLabelling(tileID);
    } else {
      if (confirm('Do you want to lock this tile for labelling?')) {
        API.lockTile(tileID, this.$store.state.user.UserInfo.id).then((res) => {
          if (res) {
            API.lockedTiles().then((lockedTiles) => {
              this.$store.dispatch('setLockedTiles', lockedTiles);
              this.selectTileForLabelling(tileID);
            });
          } else {
            alert('This tile was already locked by another user.');
          }
        });
      } else {
        this.selectTileForLabelling(tileID, true);
      }
    }
  }

  refreshTaggedTiles(show: string[], hide: string[]) {
    show.forEach((s) => {
      this.map.setLayoutProperty('border-' + s, 'visibility', 'visible');
    });
    hide.forEach((s) => {
      this.map.setLayoutProperty('border-' + s, 'visibility', 'none');
    });
  }
  selectLabel(label: string) {
    this.prevLabel = Object.assign({}, this.activeLabel);
    this.activeLabel = this.labels.find((x) => x.name === label);
  }
  onTransparencyChanged() {
    this.redraw();
    //this.map.setPaintProperty('draw-canvas-layer', 'raster-opacity', (100 - this.transparency) / 100);
  }
  shiftImage() {
    //if (this.shifting == 0) {
    //return;
    //}
    if (
      !confirm('Are you sure you want to apply shifting? Make sure your other progress is saved before you continue.')
    )
      return;

    const prevTransparency = this.transparency;
    this.transparency = 0;
    this.redraw();
    this.clearCanvas(this.ctx);
    this.ctx.drawImage(this.existingTags, 0, 0, this.CANVAS_SIZE + 1 + 1, this.CANVAS_SIZE + 1 + 1);
    const imageDataX = this.ctx.getImageData(512, 0, 1, this.CANVAS_SIZE + 1);
    const pixelDataX = new Uint32Array(imageDataX.data.buffer);
    for (let i = 0; i < pixelDataX.length; i++) {
      pixelDataX[i] = 0;
    }
    this.ctx.putImageData(imageDataX, 512, 0);
    const imageDataY = this.ctx.getImageData(0, 512, this.CANVAS_SIZE + 1, 1);
    const pixelDataY = new Uint32Array(imageDataY.data.buffer);
    for (let i = 0; i < pixelDataY.length; i++) {
      pixelDataY[i] = 0;
    }
    this.ctx.putImageData(imageDataY, 0, 512);
    this.existingTags.src = (document.querySelector('#drawCanvas') as HTMLCanvasElement).toDataURL('image/png', 100);
    this.shifting--;
    this.transparency = prevTransparency;
    this.redraw();
    //setTimeout(() => {
    //this.shiftImage();
    //}, 200);
  }
}
