
import { Component, Vue } from 'vue-property-decorator';
import MMap from '@/components/Map.vue';
import Editor from '@/components/Editor.vue';
import API from '@/services/api';
import { MapComponent } from '@/interfaces/mapComponent';
import { Unit } from '@/interfaces/unit';
import { Farm } from '@/interfaces/farm';
import { Tile } from '@/interfaces/tile';
import { Parcel } from '@/interfaces/parcel';
import { Survey } from '../interfaces/survey';
import * as Sentry from '@sentry/vue';

@Component({
  components: {
    MMap,
    Editor
  }
})
export default class Tagging extends Vue {
  $refs: {
    map: MapComponent;
  };
  parcelPriority = null;
  units: Unit[];
  farms: Farm[];
  tags: { name: string; selected: boolean }[];
  tileTags: any;
  newTag: string;
  taggedTiles: string[];
  surveys: Survey[];
  selectedTags: string[];
  selectedRegionIds = [];
  selectedUnit: Unit;
  selectedFarm: Farm;
  selectedTile: Tile;
  selectedSurvey: Survey;
  constructor() {
    super();
    this.units = [];
    this.selectedUnit = null;
    this.farms = new Array<Farm>();
    this.selectedFarm = null;
    this.selectedTile = null;
    this.tileTags = {};
    this.tags = [];
    this.newTag = '';
    //this.selectedTag = null;
  }

  async mounted() {
    try {
      await API.authorize();
      API.getUser().then((user) => {
        this.$store.dispatch('setUser', user);
        Sentry.setUser({
          email: user.UserInfo.Email,
          username: user.UserInfo.FirstName + ' ' + user.UserInfo.LastName
        });
        API.getTags().then((tags) => {
          Object.keys(tags).map((t: string) => {
            this.tags.push({ name: t, selected: false });
          });
          this.$store.dispatch('setSelectedTag', []);
          this.tags[0].selected = true;
          this.selectedTagChanged();
          //this.selectedTag = this.tags[0].name;

          this.$store.dispatch('setTags', tags);
          API.lockedTiles().then((lockedTiles) => {
            this.$store.dispatch('setLockedTiles', lockedTiles);
            API.approvedTiles().then((approvedTiles) => {
              this.$store.dispatch('setApprovedTiles', approvedTiles);
              /*API.getTaggedTiles('').then(tiles => {
                  const arr = Object.keys(tiles);
                  arr.forEach(key => {
                    this.tileTags[key] = tiles[key];
                    this.$store.dispatch('setTileTags', this.tileTags);
                  });
                  this.loadUnits();
                });*/
              this.loadTaggedTiles().then(() => {
                this.loadUnits();
              });
            });
          });
        });
      });
    } catch (error) {
      window.location.href = `${process.env.VUE_APP_AUTH_SERVER}?redir=${encodeURIComponent(window.location.href)}`;
    }
  }

  async loadUnits() {
    this.units = await API.getSurveyUnits(); //getAllUnits();
    this.units.sort((a, b) => {
      if (a.Name < b.Name) {
        return -1;
      }
      if (a.Name > b.Name) {
        return 1;
      }
      if (a.Name == b.Name) {
        return 0;
      }
    });
    this.$store.dispatch('showGlobalLoader', false);
    this.units.map((u) => {
      if (this.$refs.map != null) this.$refs.map.addUnit(u, this.getUnitTileCount(u));
    });
    if (this.$route.query.surveyId) {
      this.selectedSurvey = await API.getSurveyHierarchy(this.$route.query.surveyId as string);
      this.selectUnit(this.selectedSurvey.Unit);
    }
    //await this.loadTaggedTiles();
  }
  getUnitColor(unit: Unit): string {
    return unit != null && this.getUnitTileCount(unit) > 0 ? 'orange' : 'red';
  }
  getFarmColor(farm: Farm): string {
    return this.getFarmTileCount(farm) > 0 ? 'orange' : 'red';
  }
  getUnitTileCount(unit: Unit): number {
    let count = 0;
    this.surveys.map((s) => {
      if (s.Unit != null && s.Unit.id == unit.id) count++;
    });
    return count;
  }
  getFarmTileCount(farm: Farm): number {
    let count = 0;
    this.surveys.map((s) => {
      if (s.Unit != null && s.Farm.id == farm.id) count++;
    });
    return count;
  }
  async loadTaggedTiles() {
    this.taggedTiles = [];
    const tiles = await API.getTaggedTiles(''); //.then(tiles => {
    const arr = Object.keys(tiles);

    this.tileTags = {};
    this.$store.dispatch('setTileTags', this.tileTags);
    const surveysLoadnig = [];
    const surveys = await Promise.all(
      arr.map((key) => {
        this.taggedTiles.push(key); //[key] = tiles[key];
        this.tileTags[key] = tiles[key];
        this.$store.dispatch('setTileTags', this.tileTags);
        const surveyId = key.split('_')[0];
        if (surveysLoadnig.indexOf(surveyId) < 0) {
          surveysLoadnig.push(surveyId);
          return API.getSurveyHierarchy(surveyId);
        }
      })
    );
    this.surveys = surveys.filter((x) => x != null);
  }
  selectUnit(unit: Unit) {
    this.selectedUnit = unit;
    this.selectedFarm = null;
    if (this.selectedUnit != null) {
      this.$refs.map.selectUnit(this.selectedUnit);
      this.$refs.map.setBounds([
        this.selectedUnit.LLLong,
        this.selectedUnit.LLLat,
        this.selectedUnit.URLong,
        this.selectedUnit.URLat
      ]);
    }
  }
  selectFarm(farm: Farm) {
    this.selectedFarm = farm;
    this.$refs.map.selectFarm(this.selectedFarm);
  }
  onUnitSelected(unit: Unit) {
    this.selectedUnit = unit;
    //this.$store.dispatch('showGlobalLoader', true);
    //API.getFarms(unit.id).then(farms => {
    this.farms = unit.Farms;
    //this.$store.dispatch('showGlobalLoader', false);
    this.$refs.map.removeUnits();
    if (unit.Farms) {
      unit.Farms.map((f) => {
        this.$refs.map.addFarm(f, this.getFarmTileCount(f));
      });
    }
    //});

    this.$refs.map.setBounds([unit.LLLong, unit.LLLat, unit.URLong, unit.URLat]);
    if (this.selectedSurvey != null) {
      this.selectFarm(this.selectedSurvey.Farm);
    }
  }
  onTileSelected(tile: Tile) {
    this.selectedTile = tile;
  }
  onFarmSelected(farm: Farm) {
    this.selectedFarm = farm;
    if (this.selectedSurvey != null) {
      API.getParcel(this.selectedSurvey.ParcelID).then((parcel) => {
        this.$refs.map.setBounds([parcel.LLLong, parcel.LLLat, parcel.URLong, parcel.URLat]);
        this.$refs.map.showParcel(parcel);
        this.loadSurveys([parcel]);
      });
    } else {
      API.getParcels(farm.id).then((parcels) => {
        this.$refs.map.removeFarms();
        parcels.map((p) => {
          this.$refs.map.showParcel(p);
        });
        this.loadSurveys(parcels);
      });
    }
    this.$refs.map.setBounds([farm.LLLong, farm.LLLat, farm.URLong, farm.URLat]);
  }
  selectTileForLabelling(tileid: string) {
    const pts = tileid.split('_');
    const tags = this.$store.state.tileTags[tileid].map((t) => {
      return API.parseTag(t);
    });
    this.selectedTile = { id: tileid, survey: pts[0], x: parseInt(pts[1]), y: parseInt(pts[2]), tags: tags };
    //console.log(tileid);
  }
  addTag() {
    this.$store.state.tags[this.newTag] = this.newTag; // this.tags.push(this.newTag);
    this.tags.push({ name: this.newTag, selected: false });
    API.addTag(this.newTag, this.newTag).then((success) => {
      this.$store.dispatch(
        'setTags',
        this.tags.map((t) => t.name)
      );
      this.newTag = '';
    });
  }
  loadSurveys(parcels: Parcel[]) {
    parcels.map((p) => {
      if (this.selectedSurvey != null) {
        API.getSurvey(this.selectedSurvey.id).then((survey) => {
          this.$refs.map.showSurvey(survey, p);
        });
      } else {
        API.getSurveys(p.id).then((surveys) => {
          if (surveys != null && surveys.length > 0) {
            let survey = surveys[0];
            if (surveys.length > 1) survey = surveys[1];
            this.$refs.map.showSurvey(survey, p);
          }
        });
      }
    });
  }
  selectedTagChanged() {
    this.$store.dispatch(
      'setSelectedTag',
      this.tags
        .filter((x) => x.selected)
        .map((t) => {
          return t.name;
        })
    );
  }
}
