import { render, staticRenderFns } from "./Labelling.vue?vue&type=template&id=4f51e992&scoped=true&"
import script from "./Labelling.vue?vue&type=script&lang=ts&"
export * from "./Labelling.vue?vue&type=script&lang=ts&"
import style0 from "./Labelling.vue?vue&type=style&index=0&id=4f51e992&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f51e992",
  null
  
)

export default component.exports