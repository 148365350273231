
import { Component, Vue } from 'vue-property-decorator';
import EditorMap from '@/components/EditorMap.vue';
//import Editor from '@/components/Editor.vue';
import API from '@/services/api';
import { MapComponent } from '@/interfaces/mapComponent';
import { Unit } from '@/interfaces/unit';
import { Farm } from '@/interfaces/farm';
import { Tile } from '@/interfaces/tile';
import { Parcel } from '@/interfaces/parcel';
import { Survey } from '@/interfaces/survey';
import { noop } from 'node_modules/vue-class-component/lib/util';
import * as Sentry from '@sentry/vue';
@Component({
  components: {
    EditorMap
  }
})
export default class Tagging extends Vue {
  $refs: {
    editor: EditorMap;
  };
  //surveys: Survey[];
  parcels: Parcel[];
  //farms: Farm[];
  //unitFarms: Farm[];
  //units: Unit[];
  tagStates: Array<{ name: string; selected: boolean }>;
  selectedUnit: Unit;
  selectedFarm: Farm;
  parcelPriority = null;
  taggedTiles: any;
  myTaggedTiles: any;
  tileTags: any;
  isDirty = false;
  selectedRegionIds = [];
  selectedTile: Tile;
  selectedTileId: string;
  //surveyParcels: Map<string, Parcel>;
  //parcelFarms: Map<string, Farm>;
  //farmUnits: Map<string, Unit>;
  constructor() {
    super();
    this.selectedTileId = null;
    this.selectedTile = null;
    this.taggedTiles = {};
    this.myTaggedTiles = {};
    this.selectedUnit = null;
    this.selectedFarm = null;
    //this.units = null;
    //this.farms = null;
    //this.unitFarms = [];
    this.tileTags = null;
    //this.surveyParcels = new Map<string, Parcel>();
    //this.parcelFarms = new Map<string, Farm>();
    //this.farmUnits = new Map<string, Unit>();
    this.tagStates = [];
  }
  async mounted() {
    try {
      await API.authorize();
      API.getUser().then((user) => {
        this.$store.dispatch('setUser', user);
        Sentry.setUser({
          email: user.UserInfo.Email,
          username: user.UserInfo.FirstName + ' ' + user.UserInfo.LastName
        });
        API.getTags().then((tags) => {
          this.$store.dispatch('setTags', tags);
          Object.keys(tags).map((t) => {
            this.tagStates.push({ name: t, selected: true });
          });
          API.lockedTiles().then((lockedTiles) => {
            this.$store.dispatch('setLockedTiles', lockedTiles);
            API.approvedTiles().then((approvedTiles) => {
              this.$store.dispatch('setApprovedTiles', approvedTiles);
              //API.getTaggedTiles('').then(tiles => {
              this.loadTaggedTiles().then(() => {
                if (this.$route.query.tileId) {
                  this.selectedTileId = this.$route.query.tileId as string;
                  this.selectTileForLabelling(this.selectedTileId);
                }
              });
            });
          });
        });
      });
    } catch (error) {
      window.location.href = `${process.env.VUE_APP_AUTH_SERVER}?redir=${encodeURIComponent(window.location.href)}`;
    }
  }

  async selectTileForLabelling(tileid: string) {
    const pts = tileid.split('_');
    const survey = await API.getSurvey(pts[0]);
    if (survey) {
      const parcel = await API.getParcel(survey.ParcelID);
      const farm = await API.getFarm(parcel.FarmID);
      const unit = await API.getUnit(farm.UnitID);
      this.selectUnit(unit);
      this.selectFarm(farm);

      const tags = this.$store.state.tileTags[tileid];

      const tile = await API.getTile(tileid);

      this.selectedTile = {
        id: tileid,
        survey: pts[0],
        x: parseInt(pts[1]),
        y: parseInt(pts[2]),
        tags: tags,
        labellingStarted: tile.labellingStarted,
        labellingFinished: tile.labellingFinished,
        status: tile.status,
        note: tile.note
      };
      this.selectedTileId = tileid;
      this.$refs.editor.lockTile(this.selectedTileId);
    } else {
      alert('Survey ' + pts[0] + " doesn't exist.");
    }
    //console.log(tileid);
  }
  async loadTaggedTiles() {
    this.taggedTiles = [];
    this.myTaggedTiles = [];
    const tiles = await API.getTaggedTiles('');
    const arr = Object.keys(tiles).filter(
      (tile) => this.$store.state.lockedTiles[tile] == null || this.$store.state.lockedTiles[tile].length == 0
    );
    const finishedTiles = await API.myFinishedTiles(this.$store.state.user.UserInfo.id);

    //const arr = Object.keys(tiles);
    this.tileTags = {};
    Object.keys(tiles).forEach((key) => {
      if (!this.$store.state.lockedTiles[key]) this.taggedTiles.push(key); //[key] = tiles[key];
      if (
        this.$store.state.lockedTiles[key] != null &&
        this.$store.state.lockedTiles[key].indexOf(this.$store.state.user.UserInfo.id) >= 0
      ) {
        if (!finishedTiles || finishedTiles.filter((x) => x.indexOf(key) == 0).length == 0) {
          this.myTaggedTiles.push(key);
        }
      }
      this.tileTags[key] = tiles[key];
    });

    this.$store.dispatch('setTileTags', this.tileTags);
  }
  refreshTiles() {
    const selected = [];
    const hide = [];
    for (const key in this.tileTags) {
      if (selected.indexOf(key) < 0) {
        let found = false;
        for (let i = 0; i < this.tagStates.length; i++) {
          const sel = this.tagStates[i];
          if (sel.selected) {
            if (this.tileTags[key].indexOf(sel.name) >= 0) {
              selected.push(key);
              found = true;
              break;
            }
          }
        }
        if (!found) {
          hide.push(key);
        }
      }
    }
    this.$refs.editor.refreshTaggedTiles(selected, hide);
  }
  selectUnit(unit: Unit) {
    this.selectedUnit = unit;
    //this.onUnitSelected(unit);
  }
  onUnitSelected(unit: Unit) {
    this.selectedUnit = unit;
  }
  selectFarm(farm: Farm) {
    this.onFarmSelected(farm);
  }
  async onTileSelected(tileid: string) {
    this.selectedTileId = tileid;
    const pts = tileid.split('_');
    const tags = this.$store.state.tileTags[tileid];

    if (this.myTaggedTiles.indexOf(tileid) < 0) this.myTaggedTiles.push(tileid);
    if (this.taggedTiles.indexOf(tileid) < 0) this.taggedTiles.push(tileid);

    const tile = await API.getTile(tileid);
    this.selectedTile = {
      id: tileid,
      survey: pts[0],
      x: parseInt(pts[1]),
      y: parseInt(pts[2]),
      tags: tags,
      note: tile.note
    };
    this.selectedTileId = tileid;
  }

  onTileUnlocked() {
    this.onFarmSelected(this.selectedFarm);
  }

  onModified(dirty: boolean) {
    this.isDirty = dirty;
  }
  finishLabelling() {
    API.finishLabelling(this.selectedTileId, this.$store.state.user.UserInfo.id);
    this.selectedTileId = null;
  }

  onFarmSelected(farm: Farm) {
    this.selectedFarm = farm;
  }
  filterOption(input: string, option: any): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
}
