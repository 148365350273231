
import { Component, Vue } from 'vue-property-decorator';
import EditorMap from '@/components/EditorMap.vue';
//import Editor from '@/components/Editor.vue';
import API from '@/services/api';
import { MapComponent } from '@/interfaces/mapComponent';
import { Unit } from '@/interfaces/unit';
import { Farm } from '@/interfaces/farm';
import { Tile } from '@/interfaces/tile';
import { Parcel } from '@/interfaces/parcel';
import { Survey } from '@/interfaces/survey';
import { Labeller } from '../interfaces/labeller';
import { noop } from 'vue-class-component/lib/util';
import * as Sentry from '@sentry/vue';
@Component({
  components: {
    EditorMap
  }
})
export default class Approval extends Vue {
  $refs: {
    editor: EditorMap;
  };
  surveys: Survey[] = [];
  parcels: Parcel[] = [];
  labellers: Labeller[];
  labellerTiles: any;
  selectedLabeller: Labeller;
  selectedStatus: string;
  farms: Farm[] = [];
  unitFarms: Farm[];
  units: Unit[] = [];
  tagStates: Array<{ name: string; selected: boolean }>;
  user = '';
  selectedUnit: Unit;
  selectedFarm: Farm;
  selectedLabelledId = null;
  parcelPriority = null;
  tileTags: any;
  selectedRegionIds = [];
  selectedTile: Tile;
  allTiles: Tile[];
  selectedTileId: string;
  isDirty = false;
  surveyParcels: Map<string, Parcel>;
  parcelFarms: Map<string, Farm>;
  farmUnits: Map<string, Unit>;
  constructor() {
    super();
    this.selectedTileId = null;
    this.selectedTile = null;
    this.selectedUnit = null;
    this.selectedFarm = null;
    this.selectedStatus = 'Finished Tiles';
    this.labellerTiles = {};
    this.labellers = [];
    this.selectedLabeller = null;
    this.unitFarms = [];

    this.tileTags = {};
    this.surveyParcels = new Map<string, Parcel>();
    this.parcelFarms = new Map<string, Farm>();
    this.farmUnits = new Map<string, Unit>();
    this.tagStates = [];
  }
  async mounted() {
    this.$store.dispatch('setStatus', this.selectedStatus);
    try {
      await API.authorize();
      API.getUser().then((user) => {
        this.user = user.UserInfo.id;
        this.$store.dispatch('setUser', user);
        Sentry.setUser({
          email: user.UserInfo.Email,
          username: user.UserInfo.FirstName + ' ' + user.UserInfo.LastName
        });
        API.getTags().then((tags) => {
          this.$store.dispatch('setTags', tags);
          Object.keys(tags).map((t) => {
            this.tagStates.push({ name: t, selected: true });
          });
          this.loadData().then(async () => {
            if (this.$route.query.tileId) {
              const tile = await API.getTile(this.$route.query.tileId as string);
              if (tile.status == 'Locked') {
                await this.selectStatus('Locked Tiles');
              } else if (tile.status == 'Approved') {
                await this.selectStatus('Approved Tiles');
              } else if (tile.status == 'Processed') {
                await this.selectStatus('Processed Tiles');
              }
              this.selectedLabelledId = tile.user;
              this.selectLabeller(tile.user);
              this.selectedTileId = this.$route.query.tileId as string;
              this.selectTileForLabelling(this.selectedTileId);
            }
          });
        });
      });
    } catch (error) {
      window.location.href = `${process.env.VUE_APP_AUTH_SERVER}?redir=${encodeURIComponent(window.location.href)}`;
    }
  }
  async loadData() {
    this.selectedTile = null;
    this.selectedTileId = null;
    //this.selectedLabeller = null;
    //const tiles = await API.finishedTiles();
    this.allTiles = await API.getAllTiles();
    const lockedTiles = {};
    const allLocked = this.allTiles.filter((x) => x.user.length > 0);
    for (let i = 0; i < allLocked.length; i++) {
      lockedTiles[allLocked[i].id] = [allLocked[i].user];
    }
    this.$store.dispatch('setLockedTiles', lockedTiles);
    await this.selectStatus('Finished Tiles');
  }
  async selectStatus(status: string) {
    this.selectedStatus = status;
    this.labellers = [];
    this.labellerTiles = {};
    this.selectedLabeller = null;
    this.selectedLabelledId = null;
    this.selectedTileId = null;
    this.selectedTile = null;
    this.$store.dispatch('setStatus', status);
    //this.selectedLabeller = null;
    const tiles = {};
    let filtteredTiles = [];
    switch (status) {
      case 'Finished Tiles':
        {
          filtteredTiles = this.allTiles.filter((x) => x.status === 'Finished');
        }
        break;
      case 'Approved Tiles':
        {
          filtteredTiles = this.allTiles.filter((x) => x.status === 'Approved');
        }
        break;
      case 'Processed Tiles':
        {
          filtteredTiles = this.allTiles.filter((x) => x.status === 'Processed');
        }
        break;
      case 'Training Tiles':
        {
          filtteredTiles = this.allTiles.filter((x) => x.trainingStatus === 'Training');
        }
        break;
      case 'Testing Tiles':
        {
          filtteredTiles = this.allTiles.filter((x) => x.trainingStatus === 'Test');
        }
        break;
      default:
        filtteredTiles = this.allTiles.filter(
          (x) =>
            (x.status === 'Labelled' || x.status === 'Locked') &&
            x.labellingStarted &&
            new Date(x.labellingStarted).getTime() > 0
        );
        break;
    }
    for (let i = 0; i < filtteredTiles.length; i++) {
      tiles[filtteredTiles[i].id] = filtteredTiles[i].user;
    }
    await this.getLabellers(tiles);
  }
  selectLabeller(labellerId: string) {
    this.selectedTileId = null;
    this.selectedLabeller = this.labellers.find((x) => x.id == labellerId);
    //this.selectedLabelledId = this.selectedLabeller.Email;
    this.selectedTile = null;
    this.$store.dispatch('setUser', { UserInfo: this.selectedLabeller });
  }
  async getLabellers(tiles: any) {
    this.labellers = [];
    this.labellerTiles = {};
    for (const key in tiles) {
      const lockedUsers = [tiles[key]];
      lockedUsers.map((x) => {
        if (this.labellerTiles[x] == null) {
          this.labellerTiles[x] = [];
        }
        this.labellerTiles[x].push(key);
      });
    }
    const reqs = Object.keys(this.labellerTiles).map((x) => {
      return API.getLabeller(x);
    });
    const prevLabeller = this.selectedLabeller;
    this.selectedLabeller = null;
    await Promise.all(reqs).then((labellers) => {
      this.labellers = labellers.filter((x) => x != null);
      this.labellers.sort((a, b) => {
        return a.Email.localeCompare(b.Email);
      });
      if (prevLabeller != null && this.labellers.filter((x) => x.id == prevLabeller.id).length > 0) {
        this.selectLabeller(prevLabeller.id);
      }
    });
  }
  async selectTileForLabelling(tileid: string) {
    const pts = tileid.split('_');
    const filteredSurveys = this.surveys.filter((x) => x.id == pts[0]);
    let survey = null;
    if (filteredSurveys.length > 0) survey = filteredSurveys[0];
    else {
      survey = await API.getSurvey(pts[0]);
      this.surveys.push(survey);
    }
    const filteredParcels = this.parcels.filter((x) => x.id == survey.ParcelID);
    let parcel = null;
    if (filteredParcels.length > 0) parcel = filteredParcels[0];
    else {
      parcel = await API.getParcel(survey.ParcelID);
      this.parcels.push(parcel);
    }
    const filteredFarms = this.farms.filter((x) => x.id == parcel.FarmID);
    let farm = null;
    if (filteredFarms.length > 0) farm = filteredFarms[0];
    else {
      farm = await API.getFarm(parcel.FarmID);
      this.farms.push(farm);
    }
    let unit = null;
    const filteredUnits = this.units.filter((x) => x.id == farm.UnitID);
    if (filteredUnits.length > 0) unit = filteredUnits[0];
    else {
      unit = await API.getUnit(farm.UnitID);
      this.units.push(unit);
    }
    const tile = await API.getTile(tileid);

    this.tileTags[tileid] = tile.tags;
    this.$store.dispatch('setTileTags', this.tileTags);
    this.selectUnit(unit);
    this.selectFarm(farm);

    //const tags = this.$store.state.tileTags[tileid];
    if (!tile.trainingStatus || tile.trainingStatus === '') tile.trainingStatus = 'None';
    this.selectedTile = {
      id: tileid,
      survey: pts[0],
      x: parseInt(pts[1]),
      y: parseInt(pts[2]),
      tags: tile.tags,
      trainingStatus: tile.trainingStatus, //this.tileStatus(tileid),
      note: tile.note || ''
    };
    this.selectedTileId = tileid;
    this.$refs.editor.lockTile(this.selectedTileId);
    //console.log(tileid);
  }
  async loadSurveys(tileIds: string[]) {
    const arr = tileIds; //Object.keys(tiles);
    this.tileTags = {};
    const surveysLoadnig = [];
    const surveys = await Promise.all(
      arr.map((key) => {
        const surveyId = key.split('_')[0];
        if (surveysLoadnig.indexOf(surveyId) < 0 && !this.surveyParcels.has(surveyId)) {
          surveysLoadnig.push(surveyId);
          return API.getSurvey(surveyId);
        }
      })
    );
    this.surveys = surveys.filter((x) => x != null);
    const parcels = await Promise.all(
      this.surveys.map((s) => {
        return API.getParcel(s.ParcelID);
      })
    );
    const toUnique = parcels.filter((x) => x != null);
    this.parcels = toUnique
      .map((e) => e.id)
      .map((e, i, final) => final.indexOf(e) == i && i)
      .filter((e) => toUnique[e])
      .map((e) => toUnique[e]);
    const farmsLoading = [];
    const farms = await Promise.all(
      this.parcels.map((p) => {
        if (farmsLoading.indexOf(p.FarmID) < 0) {
          farmsLoading.push(p.FarmID);
          return API.getFarm(p.FarmID);
        }
      })
    );
    this.farms = farms.filter((x) => x != null);
    const unitsLoading = [];
    const units = await Promise.all(
      this.farms.map((f) => {
        if (unitsLoading.indexOf(f.UnitID) < 0) {
          unitsLoading.push(f.UnitID);
          return API.getUnit(f.UnitID);
        }
      })
    );

    this.units = units.filter((x) => x != null);
    //this.units.map(u => {
    //if (this.$refs.editor != null) this.$refs.editor.addUnit(u);
    //});
    //});
  }

  selectUnit(unit: Unit) {
    this.selectedUnit = unit;
    this.onUnitSelected(unit);
  }
  async tileStatus(id: string): Promise<string> {
    let status = await API.tilestatus(id);
    //let status = this.$store.state.tileStatus[id];
    if (!status) {
      status = 'None';
    }
    return status;
  }
  updateTileStatus(): void {
    API.setTilestatus(this.selectedTile.id, this.selectedTile.trainingStatus).then((res) => {
      noop();
    });
  }
  onUnitSelected(unit: Unit) {
    this.selectedUnit = unit;
    this.$refs.editor.setBounds([unit.LLLong, unit.LLLat, unit.URLong, unit.URLat]);
    this.$refs.editor.removeUnits();
    this.unitFarms = [];
    this.farms.map((f) => {
      if (f.UnitID == unit.id) {
        this.unitFarms.push(f);
        this.$refs.editor.addFarm(f);
      }
    });
  }
  selectFarm(farm: Farm) {
    this.onFarmSelected(farm);
  }
  async onTileSelected(tileid: string) {
    this.selectedTileId = tileid;
    const pts = tileid.split('_');
    //const tags = this.$store.state.tileTags[tileid];
    const tile = await API.getTile(tileid);
    this.tileTags[tileid] = tile.tags;
    //const tags = this.$store.state.tileTags[tileid];
    if (!tile.trainingStatus || tile.trainingStatus === '') tile.trainingStatus = 'None';
    this.selectedTile = {
      id: tileid,
      survey: pts[0],
      x: parseInt(pts[1]),
      y: parseInt(pts[2]),
      tags: tile.tags,
      trainingStatus: tile.trainingStatus, //this.tileStatus(tileid),
      note: tile.note || ''
    };
  }

  onTileUnlocked() {
    this.onFarmSelected(this.selectedFarm);
  }
  onModified(dirty: boolean) {
    this.isDirty = dirty;
  }
  approveLabelling() {
    //API.finishLabelling(this.selectedTileId, this.selectedLabeller.id);
    API.approveLabelling(this.selectedTileId, this.selectedLabeller.id, this.user).then((x) => {
      this.loadData();
    });
    this.selectedTileId = null;
  }

  rejectLabelling() {
    //API.finishLabelling(this.selectedTileId, this.selectedLabeller.id);
    API.rejectLabelling(this.selectedTileId, this.selectedLabeller.id).then((x) => {
      this.loadData();
    });
    this.selectedTileId = null;
  }
  filterOption(input: string, option: any): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
  downloadOrtho() {
    window.open(API._LABELLING_SERVER_URL + '/orthotif?id=' + this.selectedTileId);
  }
  downloadTiff() {
    window.open(
      API._LABELLING_SERVER_URL +
        '/taggedimage?id=' +
        this.selectedTileId +
        '&user=' +
        this.selectedLabeller.id +
        '&indexed=true&format=tiff&finished=' +
        (this.selectedStatus != 'Locked Tiles' ? 'true' : 'false')
    );
  }
  updateNotes() {
    API.saveNotes(this.selectedTileId, this.selectedTile.note);
  }
  onFarmSelected(farm: Farm) {
    this.selectedFarm = farm;
    this.parcels.map((p) => {
      if (p.FarmID == farm.id) {
        this.$refs.editor.showParcel(p);
        this.surveys.map((s) => {
          if (s.ParcelID == p.id) {
            this.$refs.editor.showSurvey(s, p);
            const tiles = Object.keys(this.tileTags).filter((x) => x.indexOf(s.id) == 0);
            //this.$refs.editor.showTaggedTiles(tiles);
          }
        });
      }
    });
    this.$refs.editor.setBounds([farm.LLLong, farm.LLLat, farm.URLong, farm.URLat]);
  }
}
