import Vue from 'vue';
import VueRouter, { NavigationGuard, NavigationGuardNext, Route } from 'vue-router';
import Tagging from '../views/Tagging.vue';
import Labelling from '../views/Labelling.vue';
import Approval from '../views/Approval.vue';
import API from '@/services/api';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Tagging',
    component: Tagging
  },
  {
    path: '/label',
    name: 'Labelling',
    component: Labelling
  },
  {
    path: '/approve',
    name: 'Approval',
    component: Approval
  },
  {
    path: '/tile',
    name: 'Tile',
    beforeEnter: async (to: Route, from: Route, next: NavigationGuardNext) => {
      try {
        await API.authorize();
        const user = await API.getUser();
        const tile = await API.getTile(to.query.id as string);
        if (
          tile.user === user.UserInfo.id &&
          (tile.status === 'Tagged' || tile.status === 'Labelling' || tile.status === 'Locked')
        ) {
          next('/label?surveyId=' + (to.query.id as string).split('_')[0] + '&tileId=' + to.query.id);
        } else {
          next('/approve?tileId=' + to.query.id);
        }
      } catch (error) {
        window.location.href = `${process.env.VUE_APP_AUTH_SERVER}?redir=${encodeURIComponent(window.location.href)}`;
      }
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
